import gql from 'graphql-tag';

import { Category } from '../fragments/Category';

export const listCategories = gql`
  query listCategories(
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listCategories(
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        ...Category
      }
      totalCount
    }
  }
  ${Category}
`;
