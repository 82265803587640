import React, {useEffect} from "react";
import {useQuery} from "react-admin";
import {useState} from "react";
import {useStyles} from "./styles";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {formatDate, useDebounce} from "../utils";
import DatePicker from "react-date-picker";
import TextField from "@material-ui/core/TextField";



export const UserStatisticGraph = () => {
    const classes = useStyles();
    const [entityScale, setEntityScale] = useState<string>('month');
    const [firstGraphData,setFirstGraphData] = useState([]);

    const [dateFrom,setDateFrom]= useState<Date>(()=>{
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 12);
        return currentDate;
    })
    const [dateTo,setDateTo]= useState<Date>(new Date());

    const [interests,setInterests] = useState([]);
    const [selectInterest, setSelectInterest] = useState<string | null>(null);

    const [searchInterest, setSearchInterest] = useState<string | null>(null);
    const debouncedSelectInterest = useDebounce(searchInterest, 500);


    const { data, isLoading, error } = useQuery({
        type: 'getOne',
        resource: 'userGraph',
        payload: { filter: {
                scale: entityScale, dateFrom: formatDate(dateFrom), dateTo: formatDate(dateTo),
                interestIds: [selectInterest]
            }}
    });



    const { data: listInterest} = useQuery({
        type: 'getList',
        resource: 'interests',
        payload: { filter: {  label: debouncedSelectInterest}, pagination: { page: 1, perPage: 50 } }
    });



    useEffect(()=>{
        if(listInterest ){
            const newArr = [];
            for (const property in listInterest) {
                newArr.push(listInterest[property]);
            }
            if(newArr.length){
                setInterests([...newArr]);
            }
        }
    },[listInterest])

    console.log('userGraph',data);


    return (
        <div className={classes.wrapDashboard}>
            <div className={classes.titleRow}>
                <h2 className={classes.title}>User activity</h2>
            </div>
            <div className={classes.wrapBlock}>
                <div className={classes.diagramWrapper}>
                    <div className={classes.filtersRows}>
                        <div>
                            <p className={classes.labelForInputs}>Select scale of time</p>
                            <Select
                                className={classes.inputSelect}
                                defaultValue={'month'}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={entityScale}
                                label="Select scale of time"
                                onChange={(event)=>setEntityScale(event.target.value as string)}
                            >
                                <MenuItem value={'day'}>day</MenuItem>
                                <MenuItem value={'month'}>month</MenuItem>
                                <MenuItem value={'year'}>year</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date From</p>
                            <DatePicker locale={"en-EN"} value={dateFrom} onChange={(date: Date) => setDateFrom(date)} maxDate={new Date()} />
                        </div>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date To</p>
                            <DatePicker locale={"en-EN"} value={dateTo} onChange={(date: Date) => setDateTo(date)} maxDate={new Date()} />
                        </div>
                    </div>
                    <div>
                        {
                            data && Object.keys(data).length != 0 && !isLoading &&
                            <LineChart
                                width={800}
                                height={400}
                                data={Object.entries(data).reverse().map(([date, activity]) => ({ date, activity }))}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 35
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tick={{ fontSize: 14, fontFamily: 'Arial' }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="activity"
                                    stroke="#0088FE"
                                    activeDot={{ r: 4 }}
                                />
                            </LineChart>
                        }
                        {
                            data && Object.keys(data).length == 0 && !isLoading &&
                            <div>no data (change filters)</div>
                        }
                    </div>
                </div>
                <div className={classes.filters}>
                    <div className={classes.filterBorderWrap}>
                        <p className={classes.labelForInputs}>Select health interest</p>
                        <div className={classes.filters}>
                            <TextField
                                size="small"
                                className={classes.filterSearch}
                                value={searchInterest}
                                onChange={(e:any)=> setSearchInterest(e.target.value)}
                                label="Seach interest"
                                variant="outlined"
                            />
                        </div>
                        <div className={classes.searchResults}>
                            <div onClick={()=> setSelectInterest(null)}
                                 className={classes.searchResultItem}
                                 style={{background:  !selectInterest ? '#0088FE' : 'transparent' }}
                            >none</div>
                            {
                                interests?.length > 0 && interests.map((item: {id:string; label: string})=>
                                    <div onClick={()=> setSelectInterest(item?.id)}
                                         className={classes.searchResultItem}
                                         key={item?.id}
                                         style={{background: item?.id == selectInterest ? '#0088FE' : 'transparent' }}
                                    >
                                        {item?.label}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
