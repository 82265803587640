import React, { FC } from 'react';
import { Record } from 'ra-core';

import { FieldProps } from '../types';

const ArrayField: FC<FieldProps<Record>> = ({ record, source }) => {
  const isData = record && source;

  const labels =
    isData &&
    record[source]?.nodes
      .map(({ label }: { label: string }) => label)
      .join(', ');

  return isData?.length ? <div>{labels}</div> : null;
};

export default ArrayField;
