import gql from "graphql-tag";

import { Pagination } from "../fragments/Pagination";
import { Interest } from "../fragments/Interest";

export const listInterests = gql`
  query listInterests(
    $filter: ListInterestsFilterInput
    $sort: ListInterestsSortInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listInterests(
      filter: $filter,
      sort: $sort,
      after: $after,
      before: $before,
      first: $first,
      last: $last,
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        ...Interest
      }
      totalCount
    }
  }
  ${Pagination}
  ${Interest}
`;
