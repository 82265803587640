import InterestIcon from '@material-ui/icons/List';

import InterestsList from './InterestsList';
import InterestCreate from './InterestCreate';
import InterestEdit from './InterestEdit';

export default {
  list: InterestsList,
  icon: InterestIcon,
  create: InterestCreate,
  edit: InterestEdit,
};
