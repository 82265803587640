import gql from "graphql-tag";

export const Like = gql`
  fragment Like on Like {
    id
    userId
    likeableId
    likeableType
    kind
  }
`;
