import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
import { useLocation } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { Notification, PasswordInput, TextInput } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";
import React, { useState } from "react";
import HeaderIcon from "./HeaderIcon";
import theme from "./theme";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    width: '35%',
    height: '100vh',
    borderRadius: 0,
    padding: '8em 5em 0',
  },
  title: {
    marginTop: 20,
  },
  subtitle: {
    color: '#7C8CAC !important',
    fontSize: 14,
    marginTop: 10,
  },
  input: {
    '& input': {
      backgroundColor: '#F9FBFC !important',
      fontSize: 16,
      color: '#2C3048',
    }
  },
  button: {
    marginTop: '1em',
    borderRadius: 20,
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    padding: '5px 20px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgb(79, 128, 142)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      color: '#FFFFFF',
    },
    '& span': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      fontSize: 16,
    }
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextInput
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderPassword = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <PasswordInput
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : "/").catch(
      (error: Error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "warning",
        );
      },
    );
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <HeaderIcon color="#8EDAEA" />
              <Typography variant="h5" color="inherit" className={classes.title}>
                {translate('auth.title')}
              </Typography>
              <Typography className={classes.subtitle}>
                {translate('auth.subtitle')}
              </Typography>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name="email"
                  component={renderInput}
                  label={translate("auth.email")}
                  disabled={loading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  name="password"
                  component={renderPassword}
                  label={translate("ra.auth.password")}
                  type="password"
                  disabled={loading}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
                className={classes.button}
              >
                {loading && <CircularProgress size={25} thickness={2} />}
                {translate("ra.auth.sign_in")}
              </Button>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(theme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
