import PromotionIcon from '@material-ui/icons/EmojiPeople';

import PromotionsList from './PromotionsList';
import PromotionCreate from './PromotionCreate';
import PromotionEdit from './PromotionEdit';

export default {
  list: PromotionsList,
  icon: PromotionIcon,
  create: PromotionCreate,
  edit: PromotionEdit,
};
