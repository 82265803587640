import gql from 'graphql-tag';

import { Attachment } from './Attachment';

export const Promotion = gql`
  fragment Promotion on Promotion {
    createdAt
    id
    kind
    options
    picture {
      ...Attachment
    }
    title
    updatedAt
    date
  }
  ${Attachment}
`;
