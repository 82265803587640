import React from 'react';
import { TextField, Datagrid, useTranslate } from 'react-admin';

import ListWithTitle from '../components/ListWithTitle';
import DataField from '../components/DataField';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';

const CategoriesList = (props: any) => {
  const translate = useTranslate();
  return (
    <ListWithTitle
      {...props}
      pagination={false}
      bulkActionButtons={false}
      exporter={false}
      title={translate('resources.categories.name', 2)}
      actions={<ListActions hasCreate={false} />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.categories.fields.id"
          sortable={false}
        />
        <TextField
          source="title"
          label="resources.categories.fields.title"
          sortable={false}
        />
        <TextField
          source="description"
          label="resources.categories.fields.description"
          sortable={false}
        />
        <DataField
          source="createdAt"
          label="resources.categories.fields.date"
          sortable={false}
        />
        <ActionsCell isDelete={false} />
      </Datagrid>
    </ListWithTitle>
  );
};

export default CategoriesList;
