import gql from 'graphql-tag';

import { Pagination } from '../fragments/Pagination';
import { Post } from '../fragments/Post';
import {Journal} from "../fragments/Journal";

export const listAllFeedsQuery = gql`
    query listAllFeeds(
        $filter: ListAllFeedsFilterInput
        $sort: ListAllFeedsSortInput
        $after: String
        $before: String
        $first: Int
        $last: Int
    ) {
        data: listAllFeeds(
            filter: $filter
            sort: $sort
            after: $after
            before: $before
            first: $first
            last: $last
        ) {
            pagination: pageInfo {
                ...Pagination
            }
            nodes {
                ...Post
                ...Journal
            }
            totalCount
        }
    }
    ${Pagination}
    ${Journal}
    ${Post}
`;
