import gql from "graphql-tag";

export const signIn = gql`
  mutation SignIn(
    $email: String!
    $password: String!
  ) {
    signIn(
      input:
        {
          email: $email
          password: $password
        }
    ) {
      accessToken
      refreshToken
    }
  }
`;
