import React from 'react';
import moment from 'moment';

const DataField = ({ record, source }: any) => {
  return record ? (
    <span>{moment(record[source]).format('MM/DD/YYYY')}</span>
  ) : null;
};

export default DataField;
