import gql from 'graphql-tag';

import { Promotion } from '../fragments/Promotion';

export const getPromotion = gql`
  query getPromotion($id: ID!) {
    data: getPromotion(id: $id) {
      ...Promotion
    }
  }
  ${Promotion}
`;
