import gql from "graphql-tag";

export const Bookmark = gql`
  fragment Bookmark on Bookmark {
    bookmarkId
    bookmarkType
    createdAt
    id
    updatedAt
  }
`;
