import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';
import CallMade from '@material-ui/icons/CallMade';

const useStyles = makeStyles({
  main: {
    flex: '1',
    marginRight: '1em',
  },
  card: {
    overflow: 'inherit',
    padding: '20px 30px',
    minHeight: 52,
    width: '18%',
    minWidth: 150,
    marginRight: '1em',
    marginBottom: '1em',
    boxShadow: '0 4px 9px 0 rgba(28,50,89,0.03)',
    border: '1px solid rgba(28,50,89,0.06)',
  },
  title: {
    color: '#7C8CAC !important',
    textTransform: 'uppercase',
    fontSize: 13,
  },
  percentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    color: '#2DB744',
    width: 15,
    height: 15,
    marginRight: 5,
  },
  percent: {
    color: '#2C2F49 !important',
    fontSize: 16,
  },
});

const UsersCount = ({ value, text, percent }) => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>{translate(text)}</Typography>
      <Typography variant="h4">{value}</Typography>
      {percent ? (
        <div className={classes.percentContainer}>
          <CallMade className={classes.icon} />
          <Typography className={classes.title}>+{percent}%</Typography>
        </div>
      ) : null}
    </Card>
  );
};

export default UsersCount;
