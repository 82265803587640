import React from 'react';
import {
  required,
  TextInput,
  ImageInput,
  ImageField,
  AutocompleteInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from '../components/SimpleForm';

const KINDS = [
  { id: 'answers_set' },
  { id: 'open_question' },
  { id: 'yes_no' },
  { id: 'text' },
  { id: 'multiple_choice' },
  { id: 'picture' },
];

const iconSize = 40;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '50%',
    height: '40%',
    minHeight: 300,
    minWidth: 500,
    backgroundColor: 'rgba(193, 206, 222, .3)',
    borderRadius: 4,
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      top: 48,
      position: 'relative',
      left: 13,
    },
  },
  removeButton: {
    display: 'inline-block',
    '& button': {
      position: 'absolute',
      top: 17,
      left: '23%',
      maxWidth: iconSize,
      maxHeight: iconSize,
      minWidth: iconSize,
      minHeight: iconSize,
      zIndex: 2,
      backgroundColor: 'rgba(255,255,255,.7)',
      padding: 0,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: 0,
    borderRadius: 4,
  },
  dropZone: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,.7)',
    padding: '10px 20px',
    borderRadius: 20,
    margin: 10,
    '& p': {
      margin: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
  },
  input: {
    width: '50%',
  },
  optionInput: {
    width: '100%',
  },
});

const PromotionForm = ({ isCreating, ...rest }: any) => {
  const classes = useStyles();

  return (
    <SimpleForm {...rest}>
      {isCreating && (
        <AutocompleteInput
          source="kind"
          allowEmpty={false}
          optionText="id"
          optionValue="id"
          choices={KINDS}
          validate={required()}
          className={classes.input}
        />
      )}
      <DateInput
        source="date"
        validate={required()}
        className={classes.input}
      />
      <FormDataConsumer>
        {({ formData, ...restProps }) => (
          <TextInput
            source="title"
            label="resources.promotions.fields.title"
            validate={
              formData.kind && formData.kind !== 'picture' ? required() : null
            }
            {...restProps}
            className={classes.input}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...restProps }) =>
          formData.kind && formData.kind === 'picture' ? (
            <ImageInput
              source="picture"
              label="resources.promotions.fields.picture"
              accept="image/*"
              labelSingle="resources.promotions.fields.selectPicture"
              validate={
                formData.kind && formData.kind === 'picture' ? required() : null
              }
              {...restProps}
              classes={classes}
            >
              <ImageField source="thumbUrl" title="title" classes={classes} />
            </ImageInput>
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...restProps }) =>
          formData.kind && ['answers_set', 'multiple_choice'].includes(formData.kind) ? (
            <ArrayInput
              source="options"
              validate={
                formData.kind &&
                ['answers_set', 'multiple_choice'].includes(formData.kind)
                  ? required()
                  : null
              }
              {...restProps}
              className={classes.input}
            >
              <SimpleFormIterator>
                <TextInput
                  source=""
                  label=""
                  className={classes.optionInput}
                  validate={required()}
                />
              </SimpleFormIterator>
            </ArrayInput>
          ) : null
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default PromotionForm;
