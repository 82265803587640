import React, {useEffect, useMemo, useState} from 'react';
import {
    Datagrid,
    ReferenceInput,
    Filter,
    AutocompleteInput,
    useTranslate,
    useMutation,
    useNotify,
    useRefresh,
    useQuery,
    Pagination, CreateButton,
    useGetList, DeleteButton, EditButton
} from 'react-admin';
import { ReactComponent as PushPin } from '../../assets/icons/push-pin.svg';
import DeleteIcon from '../../assets/icons/DeleteIcon';
import SearchInput from '../SearchInput';
import ListWithTitle from '../ListWithTitle';
import InterestInput from '../InterestInput';
import DataField from '../DataField';
import ListActions from '../ListActions';
import ActionsCell from '../ActionsCell';
import ArrayField from '../ArrayField';

import { Post } from '../../api/fragments/types/Post';
import {useStyles} from "./styled";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Select, Tooltip} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import EditIcon from "../../assets/icons/EditIcon";
import {useHistory} from "react-router";
import {TailSpin} from "react-loader-spinner";
import {useDebounce} from "../graphs/utils";


// const PostsFilter = (props: any) => {
//     const isUsersPosts = props.resource === 'posts';
//
//     return (
//         <Filter {...props}>
//             <SearchInput
//                 source="text"
//                 placeholder={`resources.${props.resource}.search`}
//                 alwaysOn
//             />
//             {!isUsersPosts && (
//                 <InterestInput
//                     source="interestId"
//                     label="resources.articles.fields.health_interests"
//                     isArray={false}
//                     alwaysOn
//                 />
//             )}
//             {!isUsersPosts && (
//                 <ReferenceInput
//                     source="authorId"
//                     label="resources.articles.fields.author"
//                     reference="users"
//                     filterToQuery={(text: string) => ({ text })}
//                     alwaysOn
//                     fullWidth
//                     emptyValue=""
//                     emptyText="Any"
//                     allowEmpty
//                     sort={false}
//                 >
//                     <AutocompleteInput
//                         optionValue="id"
//                         optionText="fullName"
//                         translateChoice={false}
//                     />
//                 </ReferenceInput>
//             )}
//         </Filter>
//     );
// };

const ArticleList = (props: any) => {
    const translate = useTranslate();
    const notify = useNotify();
    // const refresh = useRefresh();
    const history = useHistory();
    const classes = useStyles();

    const [search, setSearch] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(50);
    const [pageCount, setPageCount] = useState<number>(1);

    const { data: totalCount} = useQuery({
        type: 'getOne',
        resource: 'totalCount',
        payload: { type:  'article' }
    });
    const debouncedSearch = useDebounce(search, 600);
    const { data, total, loading, error, refetch} = useQuery({
        type: 'getList',
        resource: 'articles',
        payload: { filter: { kind: 'info', text: debouncedSearch}, pagination: { page: page, perPage: perPage } }
    });

    // const { data, ids, total, loading, loaded, error, refetch } = useGetList(
    //     'articles',
    //     { page: page, perPage: perPage },
    //      { kind: 'info', text: search},
    // );

    const refretchData = () => refetch();
    console.log('data',data);

    useEffect(()=>{
        if(totalCount){
            let pagecount = Math.floor(totalCount/perPage);
            console.log('pagination',totalCount%perPage );
            if(totalCount < perPage) pagecount = 1; else {
                if(totalCount%perPage > 0) ++pagecount;
            }
            setPageCount(pagecount);
        }
    }, [totalCount, perPage])

    // console.log('data', data, total);

    const title = useMemo(() => {
        switch (props?.filter?.kind) {
            case 'advertisement':
                return 'advertising';
            case 'info':
                return 'articles';
            case 'user':
                return 'posts';
            default:
                return '';
        }
    }, [props]);

    const [pin] = useMutation(
        {},
        {
            onSuccess: (data) => {
                const { pinned } = data.data.data.data;
                console.log(pinned);
                // refretchData();
                notify(
                    translate(`resources.${title}.pin_success`, {
                        pinned: pinned ? 'pinned' : 'unpinned',
                    }),
                    'info',
                );
            },
        },
    );

    const handlePin = (payload: Post) =>
        pin({
            type: 'update',
            resource: props.resource,
            payload: { data: {...payload, pinned: !payload.pinned} },
        });

    const [deleteArticle] = useMutation(
        {},
        {
            onSuccess: (data) => {
                // const { pinned } = data.data.data.data;
                console.log(data);
                // refetch();
                notify('item was deleted','info',);
            },
        },
    );

    const handleDelete = (payload: Post) =>
        deleteArticle({
            type: 'delete',
            resource: props.resource,
            payload: { id: payload?.id },
        });

    const divs = [];

    for (let i = 1; i <= pageCount; i++) {
        divs.push(
            <div className={classes.paginationItem}
                 style={{background: i == page ? '#8EDAEA' : '#fff'}}
                 onClick={()=> setPage(i)}
            >{i}</div>
        );
    }

    return (
        <div  className={classes.articleWrap}>
            <div className={classes.titleWrap}>
                <Typography className={classes.title}>Articles</Typography>
                <CreateButton
                    basePath={props.basePath}
                    className={classes.createButton}
                    label={'Create article'}
                />
            </div>

            <div className={classes.paginationWrap}>
                <div className={classes.pagination}>
                    <div className={classes.paginationBtns}>
                        <Button disabled={page == 1} onClick={()=>setPage((prevState)=> --prevState)}>
                            Previous page
                        </Button>
                        <div className={classes.paginationWrapList}>
                            {divs.length > 10 ?
                                <>{divs.slice(0,10)}<div className={classes.moreIcon}>{'...'}</div>{divs.slice(-1)}</>
                                : divs
                            }
                        </div>
                        <Button disabled={page == pageCount} onClick={()=>setPage((prevState)=> ++prevState)}>
                            Next page
                        </Button>
                    </div>
                    <div className={classes.rowsPerPage}>
                        <div className={classes.rowsPerPageTitle}>Rows per page </div><Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={perPage}
                            label="Per page"
                            onChange={(event)=>setPerPage(event.target.value as number)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                        </Select> of {(search as string)?.length > 1 ? total : totalCount}
                    </div>
                </div>
                <div className={classes.filters}>
                    <TextField
                        size="small"
                        className={classes.filterSearch}
                        value={search}
                        onChange={(e:any)=> setSearch(e.target.value)}
                        label="Seach article"
                        variant="outlined"
                    />
                </div>
            </div>
            {
                loading &&
                <div
                     style={{
                       width: "100%",
                       height: "100",
                       display: "flex",
                       justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <TailSpin
                        height="80"
                        width="80"
                        color="#71B8CB"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }
            {
                !loading &&
                <div className={classes.list}>
                    <div className={classes.listItemHeader}>
                        <div className={classes.headerThID}>
                            <Typography className={classes.headerGrid}>ID</Typography>
                        </div>
                        <div className={classes.headerThTitle}>
                            <Typography className={classes.headerGrid}>Article title</Typography>
                        </div>
                        <div className={classes.headerThAttachment}>
                            <Typography className={classes.headerGrid}>Attachment</Typography>
                        </div>
                        <div className={classes.headerThTotal}>
                            <Typography className={classes.headerGrid}>Engagement Total</Typography>
                        </div>
                        <div className={classes.headerThInterests}>
                            <Typography className={classes.headerGrid}>Health interests</Typography>
                        </div>
                        <div className={classes.headerThCreatedAt}>
                            <Typography className={classes.headerGrid}>Creation date</Typography>
                        </div>
                        <div>

                        </div>
                    </div>
                    {
                        !loading && data?.length >0 &&
                        data.map((article:any)=>
                            <div key={article.id} className={classes.listItem}>
                                <div className={classes.headerThID}>
                                    <Typography className={classes.valueGrid}>{article.id}</Typography>
                                </div>
                                <div className={classes.headerThTitle}>
                                    <Typography className={classes.valueGrid}>{article.title}</Typography>
                                </div>
                                <div className={classes.headerThAttachment}>
                                    <Typography className={classes.valueGrid}>{article.attachments.length}</Typography>
                                </div>
                                <div className={classes.headerThTotal}>
                                    <Typography className={classes.valueGrid}>{article.engagementTotalCount}</Typography>
                                </div>
                                <div className={classes.headerThInterests}>
                                    <Typography className={classes.valueGrid}>
                                        {article.interests.nodes.map((item: string, index: number)=>
                                            index+1 != article.interests.nodes.length ?  item.label+', ' :  item.label)
                                        }
                                    </Typography>
                                </div>
                                <div className={classes.headerThCreatedAt}>
                                    <Typography className={classes.valueGrid}>{moment(article.createdAt).format('MM/DD/YYYY')}</Typography>
                                </div>
                                <div className={classes.actionsButtons}>
                                    <div >
                                        <Button className={classes.delButton} onClick={()=>{handlePin(article);}}>
                                            <SvgIcon color={article.pinned ? 'secondary' : 'disabled'}>
                                                <PushPin />
                                            </SvgIcon>
                                        </Button>
                                    </div>
                                    <div className={classes.delButton} onClick={()=> history.push(`articles/${article.id}`)}>
                                        <EditIcon  />
                                    </div>
                                    <div className={classes.delButton} onClick={()=> handleDelete(article)}>
                                        <DeleteIcon />
                                    </div>

                                </div>
                            </div>
                        )
                    }

                </div>
            }


            {/*<ListWithTitle*/}
            {/*    filter={{ kind: props.filter.kind }}*/}
            {/*    {...props}*/}
            {/*    filters={<PostsFilter />}*/}
            {/*    perPage={5}*/}
            {/*    bulkActionButtons={false}*/}
            {/*    exporter={false}*/}
            {/*    title={title != 'posts' ? translate(`resources.${title}.name`, 2) : 'Feed'}*/}
            {/*    actions={<ListActions label={`resources.${title}.create_button`} />}*/}
            {/*    pagination={<Pagination rowsPerPageOptions={[5,10, 25, 50]} />}*/}
            {/*>*/}
            {/*    <Datagrid optimized>*/}
            {/*        <TextField*/}
            {/*            source="id"*/}
            {/*            label={`resources.${title}.fields.id`}*/}
            {/*            sortable={false}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            source="title"*/}
            {/*            label={`resources.${title}.fields.title`}*/}
            {/*            sortable={false}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            source="attachments.length"*/}
            {/*            label={`resources.${title}.fields.attachment`}*/}
            {/*            sortable={false}*/}
            {/*        />*/}
            {/*        <TextField*/}
            {/*            source="engagementTotalCount"*/}
            {/*            label={`resources.${title}.fields.engagement_total_count`}*/}
            {/*            sortable={false}*/}
            {/*        />*/}
            {/*        <ArrayField*/}
            {/*            source="interests"*/}
            {/*            label={`resources.${title}.fields.health_interests`}*/}
            {/*        />*/}
            {/*        <DataField*/}
            {/*            source="createdAt"*/}
            {/*            label={`resources.${title}.fields.date`}*/}
            {/*        />*/}
            {/*        <ActionsCell onPin={handlePin} />*/}
            {/*    </Datagrid>*/}

            {/*</ListWithTitle>*/}
        </div>

    );
};


export default ArticleList;
