import gql from 'graphql-tag';

import { Support } from './Support';
import { Like } from './Like';
import { Interest } from './Interest';
import { Bookmark } from './Bookmark';
import { PublicUser } from './PublicUser';
import { Attachment } from './Attachment';
import { Story } from './Story';

export const Journal = gql`
    fragment Journal on Journal {
        activitiesCount
        attachments {
            ...Attachment
        }
        date
        type
        createdAt
        updatedAt
        description
        id
        public
        storyTypes {
            nodes {
                ...Story
            }
        }
        user {
            ...PublicUser
        }
    }
    ${Support}
    ${Like}
    ${Interest}
    ${Bookmark}
    ${PublicUser}
    ${Attachment}
    ${Story}
`;
