import gql from 'graphql-tag';

import { Support } from './Support';
import { Like } from './Like';
import { Interest } from './Interest';
import { Bookmark } from './Bookmark';
import { PublicUser } from './PublicUser';
import { Attachment } from './Attachment';
import { Story } from './Story';

export const Post = gql`
  fragment Post on Post {
    attachments {
      ...Attachment
    }
    introImages {
      ...Attachment
    }
    author {
      ...PublicUser
    }
    bookmark {
      ...Bookmark
    }
    commentsCount
    content
    shortDescription
    createdAt
    id
    interestIds
    interestPostsCount
    engagementTotalCount
    emotionStatistic
    interests {
      nodes {
        ...Interest
      }
    }
    kind
    like {
      ...Like
    }
    likesCount
    support {
      ...Support
    }
    supportsCount
    title
    updatedAt
    ownerName
    pinned
    storyTypes {
      nodes {
        ...Story
      }
    }
    storyTypeIds
    informsCount
    commentsCount
    userSharesCount
    ownerAvatar {
      ...Attachment
    }
    pickedByEditor
    categoryId
  }
  ${Support}
  ${Like}
  ${Interest}
  ${Bookmark}
  ${PublicUser}
  ${Attachment}
  ${Story}
`;
