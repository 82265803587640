import gql from "graphql-tag";

export const getUsersStatistic = gql`
    query getUsersStatistic(
        $filter: GetUsersStatisticFilterInput
    ) {
        data: getUsersStatistic(
            filter: $filter
        ) 
    }
`;
