import React from "react";
import {
  ImageInput,
  ImageField,
  required,
  useTranslate,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const iconSize: number = 40;

const useStyles = makeStyles({
  title: {
    color: '#2C2F49 !important',
    fontSize: 16,
  },
  root: {
    position: 'relative',
    width: '20vw',
    height: '20vw',
    minHeight: 180,
    minWidth: 180,
    backgroundColor: 'rgba(193, 206, 222, .3)',
    borderRadius: '50%',
    border: '3px solid #70B8CB',
    '& .MuiFormLabel-root': {
      display: 'none'
    },
    '& .MuiFormHelperText-root': {
      position: 'relative',
      top: -23,
      left: 11,
    },
  },
  preview: {
  },
  removeButton: {
    display: 'inline-block',
    '& button': {
      position: 'absolute',
      top: '60%',
      left: 'calc(50% - 20px)',
      maxWidth: iconSize,
      maxHeight: iconSize,
      minWidth: iconSize,
      minHeight: iconSize,
      zIndex: 2,
      backgroundColor: 'rgba(255,255,255,.7)',
      padding: 0,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    margin: 0,
    borderRadius: '50%',
  },
  dropZone: {
    position: 'absolute',
    left: 20,
    right: 20,
    top: '42%',
    zIndex: 2,
    padding: '10px 20px',
    borderRadius: 20,
    border: '1px solid #71B8CB',
    backgroundColor: 'rgba(255,255,255,.7)',
    '& p': {
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)'
    }
  },
  input: {
    width: '50%',
  },
});

const AvatarUpload = ({ title, isRequired = true, ...rest }: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <>
      <Typography
        className={classes.title}
      >
        {translate(title)}
      </Typography>
      <ImageInput
        {...rest}
        label=""
        accept="image/*"
        labelSingle={isRequired ? "pos.select_photo" : "pos.photo_not_required"}
        classes={classes}
        validate={isRequired ? required() : null}
      >
        <ImageField
          source="url"
          title="title"
          classes={classes}
        />
      </ImageInput>
    </>
  );
};

export default AvatarUpload;
