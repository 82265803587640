import gql from "graphql-tag";

import { UserMeta } from "./UserMeta";
import { Country } from "./Country";
import { Attachment } from "./Attachment";
import { Interest } from "./Interest";

export const typename = "User";

export const User = gql`
  fragment User on User {
    id
    role
    email
    gender
    fullName
    dateOfBirth
    phoneNumber
    aboutMe
    education
    age
    showAge
    showInterests
    showContact
    profileCompleted
    createdAt
    updatedAt
    followersCount
    followingCount
    mainInterestIds
    mainInterests {
      nodes {
        ...Interest
      }
    }
    interestIds
    interests {
      nodes {
        ...Interest
      }
    }
    avatar {
      ...Attachment
    }
    meta {
      ...UserMeta
    }
    country {
      ...Country
    }
  }
  ${Interest}
  ${UserMeta}
  ${Country}
  ${Attachment}
`;
