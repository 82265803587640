import React from "react";
import {
  SearchInput,
  useTranslate,
} from "react-admin";
import { makeStyles, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const input = {
  marginTop: 20,
  marginBottom: 10,
  marginLeft: 'auto',
  '& div': {
    borderRadius: 20,
    backgroundColor: 'rgba(193, 206, 222, .3)',
    "&:before, &:hover:before, &:after": {
      border: 'none',
    },
  },
  '& p': {
    display: 'none'
  }
};

const Search = ({
  source,
  placeholder,
  fullWidth,
}: any) => {
  const useStyles = makeStyles({
    input: {
      ...input,
      width: fullWidth ? '100%' : '50%',
    }
  })
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <SearchInput
      source={source}
      placeholder={translate(placeholder)}
      classes={classes}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Search;
