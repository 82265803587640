import React from "react";
import {
  Toolbar,
  SaveButton,
  ListButton,
  SimpleForm,
  useTranslate,
  useMutation,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

type Attachment = { rawFile: File };

const useStyles = makeStyles(theme => ({
  title: {
    padding: '20px 20px 0',
  },
  toolbar: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  cancel: {
    borderRadius: 20,
    color: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.main,
    border: 1,
    width: 160,
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)'
    }
  },
  save: {
    borderRadius: 20,
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    width: 160,
    padding: '5px 20px',
    boxShadow: 'none',
    marginLeft: 20,
    '&:hover': {
      boxShadow: 'none',
    }
  }
}));

const CustomToolbar = (props: any) => {
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar} classes={classes}>
      <ListButton
        icon=""
        label="Cancel"
        className={classes.cancel}
      />
      <SaveButton
        icon={<span/>}
        className={classes.save}
      />
    </Toolbar>
  );
}

const SimpleFormComponent = ({
  children,
  ...rest
}: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [mutate] = useMutation();
  const saveWithNote = (data: { attachments: Attachment[], introImages: Attachment[], image: Attachment, picture: Attachment }, redirectTo: string, _a: any) => {
    if (data.attachments?.some(image => image?.rawFile || image === null)) {
      rest.record.attachments?.forEach(({ id }: { id: string }) => (
        mutate({
          type: 'delete',
          resource: 'asa',
          payload: { id }
        })
      ));
    }
    if (data.introImages?.some(image => image?.rawFile || image === null)) {
       rest.record.introImages?.forEach(({ id }: { id: string }) => (
        mutate({
          type: 'delete',
          resource: 'asa',
          payload: { id }
        })
      ));
    }
    if (rest.record.image && (data.image?.rawFile || data.image === null)) {
      mutate({
        type: 'delete',
        resource: 'asa',
        payload: { id: rest.record.image.id }
      });
    }
    if (rest.record.picture && (data.picture?.rawFile || data.picture === null)) {
      mutate({
        type: 'delete',
        resource: 'asa',
        payload: { id: rest.record.picture.id }
      });
    }
    return rest.save(data, redirectTo, _a);
  }
  return (
    <>
      <Typography
        variant="h5"
        color="inherit"
        className={classes.title}
      >
        {translate(rest.title)}
      </Typography>
      <SimpleForm {...rest} toolbar={<CustomToolbar />} redirect="list" save={saveWithNote}>
        {children}
      </SimpleForm>
    </>
  );
};

export default SimpleFormComponent;
