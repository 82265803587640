import gql from 'graphql-tag';

import { Promotion } from '../fragments/Promotion';

export const createPromotion = gql`
  mutation createPromotion(
    $title: String
    $kind: String!
    $date: Date!
    $picture: Upload
    $options: [String!]
  ) {
    data: createPromotion(
      input: {
        title: $title
        kind: $kind
        date: $date
        picture: $picture
        options: $options
      }
    ) {
      ...Promotion
    }
  }
  ${Promotion}
`;
