
import gql from "graphql-tag";


export const Diagnosis = gql`
    fragment Diagnosis on Diagnosis {
        active
        code
        createdAt
        description
        id
        label
        updatedAt
    }
`;
