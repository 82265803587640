import gql from 'graphql-tag';

import { Pagination } from '../fragments/Pagination';
import { User } from '../fragments/User';

export const listUsers = gql`
  query ListUsers(
    $filter: ListUsersFilterInput
    $sort: ListUsersSortInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listUsers(
      filter: $filter
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        ...User
      }
      totalCount
    }
  }
  ${User}
  ${Pagination}
`;
