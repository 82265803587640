import PostIcon from '@material-ui/icons/LibraryBooks';

import PostsList from './ArtcticlesList';
import PostCreate from './ArticleCreate';
import PostEdit from './ArticleEdit';

export default {
  list: PostsList,
  icon: PostIcon,
  create: PostCreate,
  edit: PostEdit,
};
