import gql from "graphql-tag";

import { Attachment } from "../fragments/Attachment";

export const exportUsers = gql`
  mutation exportUsers(
    $filter: ListUsersFilterInput
  ) {
    data: exportUsers(
      filter: $filter
    ) {
      file {
        ...Attachment
      }
    }
  }
  ${Attachment}
`;
