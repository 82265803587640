import React from "react";
import {
  ReferenceArrayInput,
  TextInput,
  required,
  AutocompleteArrayInput,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from "../components/SimpleForm";
import AvatarUpload from "../components/AvatarUpload";

const useStyles = makeStyles({
  input: {
    width: '50%',
  },
});

const GroupForm = (props: any) => {
  const classes = useStyles();
  return (
    <SimpleForm {...props}>
      <AvatarUpload
        source="image"
        title="resources.groups.upload_photo"
      />
      <TextInput
        source="title"
        label="resources.groups.fields.group_name"
        validate={required()}
        className={classes.input}
      />
      <ReferenceArrayInput
        source="interestIds"
        label="resources.posts.fields.health_interests"
        reference="interests"
        validate={required()}
        filterToQuery={(label: string) => ({ label })}
      >
        <AutocompleteArrayInput
          optionValue="id"
          optionText="label"
          translateChoice={false}
        />
      </ReferenceArrayInput>
      <TextInput
        source="description"
        label="resources.groups.fields.description"
        validate={required()}
        multiline
        className={classes.input}
        rows="6"
      />
    </SimpleForm>
  );
};

export default GroupForm;
