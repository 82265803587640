const theme = {
  palette: {
    primary: {
      main: '#71B8CB',
    },
    secondary: {
      main: '#8EDAEA',
    },
    error: {
      main: '#FF6759',
    },
    background: {
      default: '#F4F6FC'
    },
    text: {
      primary: '#2C2F49',
      secondary: '#7C8CAC',
    }
  },
  typography: {
    h5: {
      fontSize: 28,
      fontWeight: 600,
    },
    body1: {
      fontSize: 16,
    },
    caption: {
      fontSize: 10,
    },
    fontFamily: "'Montserrat', sans-serif",
  },
  overrides: {
    MuiDrawer: {
      root: {
        backgroundColor: 'white',
        marginRight: 10,
      },
    },
    MuiPaper: {
      root: {
        '&.MuiCard-root': {
          boxShadow: 'none !important',
        },
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-child(2n)': {
          backgroundColor: '#F4F6FC',
        }
      },
    },
    MuiTableCell: {
      root: {
        '& a[href^="mailto"]': {
          color: 'inherit'
        }
      },
      head: {
        color: '#7C8CAC',
        '& span': {
          whiteSpace: 'nowrap',
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#F2F7F8',
      },
      inputMarginDense: {
        paddingTop: 19,
        paddingBottom: 3,
      }
    },
    MuiInputBase: {
      root: {
        fontSize: 16,
      }
    },
    MuiListItemIcon: {
      root: {
        color: '#C1CEDE'
      }
    },
    MuiTypography: {
      root: {
        color: '#2C2F49 !important',
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
        }
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: 16,
      },
      textSizeSmall: {
        fontSize: 16,
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 400,
      }
    },
    MuiDialogTitle: {
      padding: '16px 24px 0',
      root: {
        '& h2': {
          textAlign: 'center',
        }
      }
    },
    MuiDialogContent: {
      root: {
        '& p': {
          textAlign: 'center',
          minWidth: 300,
          fontSize: 15,
        }
      }
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
        '& button': {
          borderRadius: 20,
          width: 160,
          color: '#8EDAEA',
          borderStyle: 'solid',
          borderColor: '#8EDAEA',
          border: 1,
          padding: '5px 20px',
          '&:hover': {
            backgroundColor: 'none'
          },
          '&:nth-child(2)': {
            color: '#FFFFFF',
            backgroundColor: '#FF6759',
            borderColor: '#FF6759',
            padding: '5px 20px',
            boxShadow: 'none',
            marginLeft: 20,
            '&:hover': {
              boxShadow: 'none',
            }
          },
          '& svg': {
            display: 'none'
          }
        }
      }
    },
    MuiTouchRipple: {
      root: {
        display: 'none'
      }
    }
  }
};

export default theme;
