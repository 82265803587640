import React, { useState, Fragment } from 'react';
import {
  DeleteButton,
  EditButton,
  useTranslate,
  Button,
  Confirm,
} from 'react-admin';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconBlock from '@material-ui/icons/Block';
import SvgIcon from '@material-ui/core/SvgIcon';

import { ReactComponent as PushPin } from '../assets/icons/push-pin.svg';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    '& button, a': {
      minWidth: 40,
    },
  },
});

const ActionsCell = ({
  isEdit = true,
  isDelete = true,
  onBlock,
  onPin,
  ...rest
}: any) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const handlePin = () => {
    onPin({ ...rest.record, pinned: !rest.record.pinned });
  };

  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div className={classes.actions}>
      {onBlock && (
        <Fragment>
          <Tooltip title="Block user" disableHoverListener={false}>
            <div>
              <Button onClick={toggleOpen}>
                <IconBlock />
              </Button>
            </div>
          </Tooltip>
          <Confirm
            isOpen={open}
            title={translate(`resources.${rest.resource}.block_title`)}
            content={translate(`resources.${rest.resource}.block_content`)}
            onConfirm={() => {
              toggleOpen();
              onBlock(rest.record.id);
            }}
            onClose={toggleOpen}
          />
        </Fragment>
      )}
      {isDelete ? (
        <Tooltip title={translate(`resources.${rest.resource}.delete_tooltip`)}>
          <div>
            <DeleteButton
              {...rest}
              label=""
              undoable={false}
              confirmTitle={translate(
                `resources.${rest.resource}.delete_title`,
              )}
              confirmContent={translate(
                `resources.${rest.resource}.delete_content`,
              )}
            />
          </div>
        </Tooltip>
      ) : null}
      {isEdit ? (
        <Tooltip title={translate(`resources.${rest.resource}.edit_tooltip`)}>
          <div>
            <EditButton {...rest} label="" />
          </div>
        </Tooltip>
      ) : null}
      {onPin ? (
        <Tooltip title={translate(`resources.${rest.resource}.pin_tooltip`)}>
          <div>
            <Button onClick={handlePin}>
              <SvgIcon color={rest.record.pinned ? 'secondary' : 'disabled'}>
                <PushPin />
              </SvgIcon>
            </Button>
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default ActionsCell;
