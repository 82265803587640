import GroupIcon from "@material-ui/icons/Group";

import GroupsList from "./GroupsList";
import GroupCreate from "./GroupCreate";
import GroupEdit from "./GroupEdit";

export default {
  list: GroupsList,
  icon: GroupIcon,
  create: GroupCreate,
  edit: GroupEdit,
};
