import React from 'react';
import ArticleList from "../components/ArticleList/ArticleList";



const ArticlesList = (props: any) => {
  console.log('props',props);
  return <ArticleList {...props} filter={{ kind: 'info' }} />;
};

export default ArticlesList;
