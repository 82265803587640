import React from 'react';
import { TextField, Datagrid, Filter, useTranslate } from 'react-admin';

import ListWithTitle from '../components/ListWithTitle';
import SearchInput from '../components/SearchInput';
import DataField from '../components/DataField';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';

const InterestsFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput
      source="label"
      placeholder="resources.interests.search"
      alwaysOn
    />
  </Filter>
);

const InterestsList = (props: any) => {
  const translate = useTranslate();
  return (
    <ListWithTitle
      {...props}
      filters={<InterestsFilter />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      title={translate('resources.interests.name', 2)}
      actions={<ListActions label="resources.interests.create_button" />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.interests.fields.id"
          sortable={false}
        />
        <TextField
          source="label"
          label="resources.interests.fields.title"
          sortable={false}
        />
        <TextField
          source="status"
          label="resources.interests.fields.status"
          sortable={false}
        />
        <DataField
          source="createdAt"
          label="resources.interests.fields.date"
          sortable={false}
        />
        <ActionsCell />
      </Datagrid>
    </ListWithTitle>
  );
};

export default InterestsList;
