import React from 'react';
import { Edit } from 'react-admin';

import PostForm from '../components/PostForm';

const PostEdit = (props: any) => {
  return (
    <Edit {...props}>
      <PostForm title="resources.articles.edit" isRequiredOwner={false} />
    </Edit>
  );
};

export default PostEdit;
