import Card from "@material-ui/core/Card";
import { useTranslate, Title } from "react-admin";

import React from "react";

const Configuration = () => {
  const translate = useTranslate();

  return (
    <Card>
      <Title title={translate("pos.configuration")} />
    </Card>
  );
};

export default Configuration;
