import gql from "graphql-tag";

import { Group } from "../fragments/Group";

export const createGroup = gql`
  mutation createChatGroup(
    $title: String!
    $description: String!
    $interestIds: [ID!]!
    $image: Upload
  ) {
    data: createChatGroup(
      input: {
        title: $title,
        description: $description,
        interestIds: $interestIds,
        image: $image
      }
    ) {
      ...Group
    }
  }
  ${Group}
`;
