import gql from 'graphql-tag';

import { Category } from '../fragments/Category';

export const editCategory = gql`
  mutation updateCategory(
    $id: ID!
    $title: String
    $description: String
    $image: Upload
  ) {
    data: updateCategory(
      id: $id
      input: { title: $title, description: $description, image: $image }
    ) {
      ...Category
    }
  }
  ${Category}
`;
