import React from 'react';
import {
  TextField,
  Datagrid,
  Filter,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import ListWithTitle from '../components/ListWithTitle';

const AnswersFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      source="userId"
      label="resources.answers.fields.author"
      reference="users"
      filterToQuery={(text: string) => ({ text })}
      alwaysOn
      fullWidth
      emptyValue=""
      emptyText="Any"
      allowEmpty
      sort={false}
    >
      <AutocompleteInput
        optionValue="id"
        optionText="fullName"
        translateChoice={false}
      />
    </ReferenceInput>
    <ReferenceInput
      source="promotionId"
      label="resources.answers.fields.promotion"
      reference="promotions"
      filterToQuery={(title: string) => ({ title })}
      alwaysOn
      fullWidth
      emptyValue=""
      emptyText="Any"
      allowEmpty
      sort={false}
    >
      <AutocompleteInput
        optionValue="id"
        optionText="title"
        translateChoice={false}
      />
    </ReferenceInput>
  </Filter>
);

const AnswersList = (props: any) => {
  return (
    <ListWithTitle
      {...props}
      filters={<AnswersFilter />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      title="resources.answers.name"
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.answers.fields.id"
          sortable={false}
        />
        <TextField
          source="text"
          label="resources.answers.fields.answer"
          sortable={false}
        />
      </Datagrid>
    </ListWithTitle>
  );
};

export default AnswersList;
