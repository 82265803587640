import React from "react";
import { Layout, Sidebar } from "react-admin";
import { makeStyles } from '@material-ui/core';
import AppBar from "./AppBar";
import Menu from "./Menu";

const useStyles = makeStyles({
    drawerPaper: {
      width: 60,
      '& .MuiPaper-root': {
        width: 'fit-content',
        marginTop: '2.5em',
      }
    },
  },
  { name: 'RaSidebar' }
);

const CustomSidebar = (props: any) => {
  const classes = useStyles();
  return <Sidebar {...props} className={classes.drawerPaper} />
}

export default (props: any) => {
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
    />
  );
};
