import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';

import PostsList from './PostsList';
import PostCreate from './PostCreate';
import PostEdit from './PostEdit';

export default {
  list: PostsList,
  icon: CollectionsBookmarkIcon,
  create: PostCreate,
  edit: PostEdit,
};
