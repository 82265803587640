import SpamIcon from '@material-ui/icons/NotInterested';

import WordsList from './WordsList';
import WordCreate from './WordCreate';

export default {
  list: WordsList,
  icon: SpamIcon,
  create: WordCreate,
};
