import gql from "graphql-tag";

import { Interest } from "../fragments/Interest";

export const createInterest = gql`
  mutation createInterest(
    $label: String!
  ) {
    data: createInterest(
      input: {
        label: $label,
      }
    ) {
      ...Interest
    }
  }
  ${Interest}
`;
