import gql from "graphql-tag";

import { Post } from "../fragments/Post";

export const getPost = gql`
  query getPost($id: ID!) {
    data: getPost(id: $id) {
      ...Post
    }
  }
  ${Post}
`;
