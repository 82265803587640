import gql from 'graphql-tag';

import { Post } from '../fragments/Post';

export const editPost = gql`
  mutation updatePost(
    $id: ID!
    $title: String
    $content: String
    $shortDescription: String
    $kind: String!
    $interestIds: [ID!]!
    $attachments: [Upload!]
    $introImages: [Upload!]
    $ownerAvatar: Upload
    $ownerName: String
    $pickedByEditor: Boolean
    $categoryId: ID
    $pinned: Boolean
    $createdAt: ISO8601DateTime
  ) {
    data: updatePost(
      id: $id
      input: {
        title: $title
        content: $content
        shortDescription: $shortDescription
        kind: $kind
        interestIds: $interestIds
        attachments: $attachments
        introImages: $introImages
        ownerAvatar: $ownerAvatar
        ownerName: $ownerName
        pickedByEditor: $pickedByEditor
        categoryId: $categoryId
        pinned: $pinned
        createdAt: $createdAt
      }
    ) {
      ...Post
    }
  }
  ${Post}
`;
