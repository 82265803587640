import React from "react";
import {
  ReferenceArrayInput,
  ReferenceInput,
  AutocompleteArrayInput,
  AutocompleteInput,
} from "react-admin";

const InterestInput = (props: any) => {
  const RefComponent = props.isArray ? ReferenceArrayInput : ReferenceInput;
  const AutoComponent = props.isArray ? AutocompleteArrayInput : AutocompleteInput;
  const emptyValue = props.isArray ? {} : {
    emptyValue: "",
    emptyText: "Any",
    allowEmpty: true,
  };
  return (
    <RefComponent
      {...props}
      reference="interests"
      filterToQuery={(label: string) => ({ label })}
      fullWidth
      {...emptyValue}
    >
      <AutoComponent
        optionValue="id"
        optionText="label"
        translateChoice={false}
      />
    </RefComponent>
  );
};

export default InterestInput;
