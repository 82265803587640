import React, { FC, useState } from 'react';
import { Record } from 'ra-core';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { FieldProps } from '../types';

const MAX_CONTENT_LENGTH = 70;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      maxWidth: 800,
      width: '100%',
      backgroundColor: '#ffffff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 6,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    },
    button: {
      alignSelf: 'flex-end',
      color: '#FFFFFF',
    },
    field: {
      display: 'flex',
    },
  }),
);

const LongTextField: FC<FieldProps<Record>> = ({ record: { content } }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function createMarkup() { return {__html: content}; }
  const body = (
    <div className={classes.paper}>
      <Typography id="simple-modal-title" variant="h6" component="h3">
        Post text
      </Typography>
      <Typography id="simple-modal-description"><div dangerouslySetInnerHTML={createMarkup()} /></Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClose}
        className={classes.button}
      >
        Close
      </Button>
    </div>
  );

  const partialContent =
    content.length > MAX_CONTENT_LENGTH
      ? `${content.slice(0, MAX_CONTENT_LENGTH).trim()}...`
      : content;
  function createPartialMarkup() { return {__html: partialContent}; }
  return (
    <>
      <div className={classes.field}>
        {partialContent ? (
          <>
            <Typography variant="body2" component="span" onClick={handleOpen}>
                <div dangerouslySetInnerHTML={createPartialMarkup()} />
            </Typography>
          </>
        ) : null}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};

LongTextField.defaultProps = {
  label: 'resources.posts.fields.health_interests',
  sortable: false,
};

export default LongTextField;
