import { Operation, ApolloLink } from "apollo-link";
import { onError, ErrorLink } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { invariant } from "ts-invariant";

import introspectionQueryResultData from "./fragmentTypes.json";
import { Session } from "../services/session";

const introspectionFragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({
  fragmentMatcher: introspectionFragmentMatcher,
});

export interface PresetConfig {
  request?: (operation: Operation) => Promise<void> | void;
  uri: string;
  headers?: any;
  onError?: ErrorLink.ErrorHandler;
  name?: string;
  version?: string;
  assumeImmutableResults?: boolean;
  session: Session;
}

export default class DefaultClient<TCache> extends ApolloClient<TCache> {
  constructor(config: PresetConfig) {
    const {
      uri,
      headers,
      onError: errorCallback,
      name,
      version,
      session,
    } = config;

    const refreshLink = setContext(async () => {
      const isExpired = await session.isExpired();

      if (!isExpired) {
        return {};
      }

      const refreshToken = await session.getRefreshToken();
      const response = await fetch(uri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation($input: UpdateTokenInput!) {
              data: updateToken(input: $input) {
                accessToken
                refreshToken
              }
            }
          `,
          variables: {
            input: {
              refreshToken,
            },
          },
        }),
      });

      const result = await response.json();
      const data = result?.data?.data;

      if (!data) {
        return await session.delete();
      }

      return await session.update(data);
    });

    const tokenLink = setContext(async () => {
      const token = await session.getAccessToken();

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const errorLink = errorCallback
      ? onError(errorCallback)
      : onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
              // tslint:disable-next-line
              invariant.warn(
                `[GraphQL error]: Message: ${message}, Location: ` +
                  `${locations}, Path: ${path}`,
              ),
            );
          }
          if (networkError) {
            // tslint:disable-next-line
            invariant.warn(`[Network error]: ${networkError}`);
          }
        });

    const httpLink = createUploadLink({
      uri: uri || "/graphql",
      fetch,
      fetchOptions: {},
      credentials: "same-origin",
      headers: headers || {},
    });

    const link = ApolloLink.from(
      [errorLink, refreshLink, tokenLink, httpLink].filter(
        x => !!x,
      ) as ApolloLink[],
    );

    // super hacky, we will fix the types eventually
    super({
      cache,
      link,
      name,
      version
    } as any);
  }
}
