import gql from "graphql-tag";

export const Pagination = gql`
  fragment Pagination on PageInfo {
    startCursor
    endCursor
    hasPreviousPage
    hasNextPage
  }
`;
