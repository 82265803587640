import React from 'react';
import { Create } from 'react-admin';

import PromotionForm from './PromotionForm';

const PromotionCreate = (props: any) => {
  return (
    <Create {...props}>
      <PromotionForm title="resources.promotions.create" isCreating />
    </Create>
  );
};

export default PromotionCreate;
