import buildApolloClient from 'ra-data-graphql';
import {CREATE, DELETE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE,} from 'ra-core';
import pluralize from 'pluralize';

import {listUsers} from './queries/listUsers';
import {deleteUser} from './mutations/deleteUser';

import {listGroups} from './queries/listGroups';
import {editGroup} from './mutations/editGroup';
import {createGroup} from './mutations/createGroup';
import {deleteGroup} from './mutations/deleteGroup';
import {getGroup} from './queries/getGroup';

import {listArticles} from './queries/listArticles';
import {editPost} from './mutations/editPost';
import {createPost} from './mutations/createPost';
import {deletePost} from './mutations/deletePost';
import {getPost} from './queries/getPost';

import {listInterests} from './queries/listInterests';
import {editInterest} from './mutations/editInterest';
import {createInterest} from './mutations/createInterest';
import {deleteInterest} from './mutations/deleteInterest';
import {getInterest} from './queries/getInterest';

import {listCategories} from './queries/listCategories';
import {editCategory} from './mutations/editCategory';
import {getCategory} from './queries/getCategory';

import {getSpamWords} from './queries/getSpamWords';
import {createSpamWord} from './mutations/createSpamWord';
import {deleteSpamWord} from './mutations/deleteSpamWord';

import {listPromotions} from './queries/listPromotions';
import {editPromotion} from './mutations/editPromotion';
import {createPromotion} from './mutations/createPromotion';
import {deletePromotion} from './mutations/deletePromotion';
import {getPromotion} from './queries/getPromotion';

import {listAnswers} from './queries/listAnswers';

import {listCountries} from './queries/listCountries';

import {deleteAsa} from './mutations/deleteAsa';

import {getDashboard} from './queries/getDashboard';

import {exportUsers} from './mutations/exportUsers';

import {createBlockedUser} from './mutations/createBlockedUser';
import {listAllFeedsQuery} from "./queries/listAllFeeds";

import { getUsersActivityStatistic} from "./queries/graphs/getFirstGraph";
import {getTotal} from "./queries/getTotal";
import {getEmotionsStatistic} from "./queries/graphs/getEmotionsStatistic";
import {getStoryTypesStatistic} from "./queries/graphs/getStoryGraph";
import {getUsersStatistic} from "./queries/graphs/getUserStatisctic";
import {listDiagnoses} from "./queries/getDiagnosesList";
import {getCheckInStatistic} from "./queries/graphs/getCheckInStatistic";

const queries = {
  User: {
    [GET_LIST]: listUsers,
    [DELETE]: deleteUser,
  },
  Group: {
    [GET_LIST]: listGroups,
    [UPDATE]: editGroup,
    [CREATE]: createGroup,
    [DELETE]: deleteGroup,
    [GET_ONE]: getGroup,
  },
  Article: {
    [GET_LIST]: listArticles,
    [UPDATE]: editPost,
    [CREATE]: createPost,
    [DELETE]: deletePost,
    [GET_ONE]: getPost,
  },
  Post: {
    [GET_LIST]: listAllFeedsQuery,
    [UPDATE]: editPost,
    [CREATE]: createPost,
    [DELETE]: deletePost,
    [GET_ONE]: getPost,
  },
  Advertising: {
    [GET_LIST]: listArticles,
    [UPDATE]: editPost,
    [CREATE]: createPost,
    [DELETE]: deletePost,
    [GET_ONE]: getPost,
  },
  Category: {
    [GET_LIST]: listCategories,
    [UPDATE]: editCategory,
    [GET_ONE]: getCategory,
  },
  Interest: {
    [GET_LIST]: listInterests,
    [UPDATE]: editInterest,
    [CREATE]: createInterest,
    [DELETE]: deleteInterest,
    [GET_MANY]: listInterests,
    [GET_ONE]: getInterest,
  },
  Diagnoses: {
    [GET_LIST]: listDiagnoses,
  },
  SpamWord: {
    [GET_LIST]: getSpamWords,
    [CREATE]: createSpamWord,
    [DELETE]: deleteSpamWord,
  },
  Promotion: {
    [GET_LIST]: listPromotions,
    [UPDATE]: editPromotion,
    [CREATE]: createPromotion,
    [DELETE]: deletePromotion,
    [GET_ONE]: getPromotion,
  },
  Answer: {
    [GET_LIST]: listAnswers,
  },
  Country: {
    [GET_LIST]: listCountries,
  },
  Dashboard: {
    [GET_ONE]: getDashboard,
  },
  FirstGraph: {
    [GET_ONE]: getUsersActivityStatistic,
  },
  EmotionGraph: {
    [GET_ONE]: getEmotionsStatistic,
  },
  StoryGraph: {
    [GET_ONE]: getStoryTypesStatistic,
  },
  UserGraph: {
    [GET_ONE]: getUsersStatistic,
  },
  CheckInGraph: {
    [GET_ONE]: getCheckInStatistic,
  },
  TotalCount: {
    [GET_ONE]: getTotal,
  },
  Asa: {
    [DELETE]: deleteAsa,
  },
  Export: {
    [GET_ONE]: exportUsers,
  },
  BlockedUser: {
    [DELETE]: createBlockedUser,
  },
};

const getGqlResource = (resource: string) => {
  switch (resource) {
    case 'users':
      return 'User';
    case 'groups':
      return 'Group';
    case 'articles':
      return 'Article';
    case 'posts':
      return 'Post';
    case 'interests':
      return 'Interest';
    case 'diagnoses':
      return 'Diagnoses';
    case 'categories':
      return 'Category';
    case 'spams':
      return 'SpamWord';
    case 'promotions':
      return 'Promotion';
    case 'answers':
      return 'Answer';
    case 'countries':
      return 'Country';
    case 'advertising':
      return 'Advertising';
    case 'dashboard':
      return 'Dashboard';
    case 'firstGraph':
      return 'FirstGraph';
    case 'emotionGraph':
      return 'EmotionGraph';
    case 'storyGraph':
      return 'StoryGraph';
    case 'userGraph':
      return 'UserGraph';
    case 'checkInGraph':
      return 'CheckInGraph';
    case 'totalCount':
      return 'TotalCount';
    case 'asa':
      return 'Asa';
    case 'export':
      return 'Export';
    case 'blocked':
      return 'BlockedUser';
    case 'storyTypes':
      return 'StoryTypes';

    default:
      throw new Error(`Unknown resource ${resource}`);
  }
};

const buildQuery = (introspectionResults: object) => {
  return (action: string, resource: string, params: object) => {
    if (action === GET_LIST) {
      console.log('GET_LIST: ', resource);
      const query = queries[resource][action];
      const {
        pagination: { page, perPage },
        filter,
        sort,
      } = params;

      if (filter?.ageTo < 0) {
        filter.ageTo = 0;
      }

      if (filter?.ageFrom < 0) {
        filter.ageFrom = 0;
      }

      const isSortedResource = resource === 'User' || resource === 'Post';

      return {
        query,
        variables: {
          after: btoa(perPage * (page - 1)),
          first: perPage,
          filter,
          sort:
            sort && isSortedResource
              ? {
                  [sort.field]: sort.order,
                }
              : null,
        },
        parseResponse: ({
          data: {
            data: { nodes: data, totalCount: total, words, pagination: pageInfo },
          },
        }: any) => {
          console.log(`GET_LIST: ${resource}`, data);
          return {
            data:
                resource === 'SpamWord'
                    ? words.map((word: string) => ({id: word}))
                    : data,
            total: resource === 'SpamWord' ? words?.length : total,
          };
        },
      };
    } else if (action === UPDATE) {
      const query = queries[resource][action];

      const attachments = params.data.attachments
        ?.map((image) => image?.rawFile)
        .filter((image) => image);
      const introImages = params.data.introImages
        ?.map((image) => image?.rawFile)
        .filter((image) => image);
      const ownerAvatar = params.data.ownerAvatar?.rawFile || undefined;
      const image = params.data.image?.rawFile || undefined;
      const picture = params.data.picture?.rawFile || undefined;

      let kind;
      if (resource === 'Advertising') {
        kind = 'advertisement';
      } else if (resource === 'Post') {
        if(params.data.kind === 'journal') kind = 'journal'; else kind = 'user';
      } else if (resource === 'Promotion') {
        kind = params.data.kind;
      } else {
        kind = 'info';
      }

      return {
        query,
        variables: {
          ...params.data,
          attachments,
          introImages,
          ownerAvatar,
          image,
          picture,
          kind,
        },
        parseResponse: (data) => {
          return { data };
        },
      };
    } else if (action === GET_ONE) {
      const query = queries[resource][action];
      return {
        query,
        variables: params,
        parseResponse: ({ data }) => {
          return data;
        },
      };
    } else if (action === DELETE) {
      const query = queries[resource][action];
      return {
        query,
        variables: params,
        parseResponse: (data: object) => {
          return data;
        },
      };
    } else if (action === CREATE) {
      const query = queries[resource][action];
      const attachments = params.data.attachments
        ?.map((image) => image?.rawFile)
        .filter((image) => image);
      const introImages = params.data.introImages
        ?.map((image) => image?.rawFile)
        .filter((image) => image);
      const ownerAvatar = params.data.ownerAvatar?.rawFile || undefined;
      const image = params.data.image?.rawFile || undefined;
      const picture = params.data.picture?.rawFile || undefined;
      const kind = resource === 'Advertising' ? 'advertisement' : 'info';

      return {
        query,
        variables: {
          ...params.data,
          attachments,
          introImages,
          ownerAvatar,
          picture,
          kind: resource === 'Promotion' ? params.data.kind : kind,
          image,
        },
        parseResponse: ({ data }: object) => {
          return data;
        },
      };
    } else if (action === GET_MANY) {
      const query = queries[resource][action];
      return {
        query,
        variables: {
          filter: params,
        },
        parseResponse: ({
          data: {
            data: { nodes: data },
          },
        }) => {
          return { data };
        },
      };
    }
  };
};

export default (options) => {
  return buildApolloClient({
    ...options,
    introspection: {
      operationNames: {
        [GET_LIST]: (type) => `list${pluralize(type.name)}`,
        [GET_ONE]: (type) => `get${type.name}`,
        [GET_MANY]: (type) => `list${pluralize(type.name)}`,
        [GET_MANY_REFERENCE]: (type) => `list${pluralize(type.name)}`,
        [CREATE]: (type) => `create${type.name}`,
        [UPDATE]: (type) => `update${type.name}`,
        [DELETE]: (type) => `delete${type.name}`,
      },
    },
    buildQuery: buildQuery,
  }).then(
    (provider) => (type, resource, params) =>
      provider(type, getGqlResource(resource), params),
  );
};
