import gql from "graphql-tag";

import { Pagination } from "../fragments/Pagination";
import {Diagnosis} from "../fragments/Diagnos";

export const listDiagnoses = gql`
    query listDiagnoses(
        $filter: ListDiagnosesFilterInput
        $sort: ListDiagnosesSortInput
        $after: String
        $before: String
        $first: Int
        $last: Int
    ) {
        data: listDiagnoses(
            filter: $filter,
            sort: $sort,
            after: $after,
            before: $before,
            first: $first,
            last: $last,
        ) {
            pagination: pageInfo {
                ...Pagination
            }
            nodes {
                ...Diagnosis
            }
        }
    }
    ${Pagination}
    ${Diagnosis}
`;
