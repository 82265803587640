import React from 'react';
import { List } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  title: {
    padding: '30px 5px 10px',
  },
  content: {
    '& td, th': {
      padding: 10,
    }
  },
});

const ListWithTitle = ({ children, ...rest }: any) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h5"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <List {...rest} classes={classes}>
        {children}
      </List>
    </>
  );
};

export default ListWithTitle;
