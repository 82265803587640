import gql from 'graphql-tag';

import { Attachment } from './Attachment';

export const Category = gql`
  fragment Category on Category {
    createdAt
    description
    id
    image {
      ...Attachment
    }
    title
    updatedAt
  }
  ${Attachment}
`;
