import React, {
  forwardRef,
  cloneElement,
  FC,
  ReactElement,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { NavLink, NavLinkProps } from 'react-router-dom';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const NavLinkRef = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

const useStyles = makeStyles(
  theme => ({
    root: {
      color: theme.palette.text.secondary,
      width: 60,
      height: 50,
    },
    active: {
      color: theme.palette.secondary.main,
      backgroundColor: 'rgba(142, 218, 234, .1)',
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      borderRight: 3,
      "& svg": {
        color: theme.palette.secondary.main,
      }
    },
    icon: {
      minWidth: theme.spacing(5),
    },
  }),
  { name: 'RaMenuItemLink' }
);

const MenuItemLink: FC<MenuItemLinkProps> = forwardRef(
  (
    {
      primaryText,
      leftIcon,
      onClick,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    const renderMenuItem = () => {
      return (
        <MenuItem
          className={classes.root}
          activeClassName={classes.active}
          component={NavLinkRef}
          ref={ref}
          {...props}
          onClick={onClick}
          dense={true}
          sidebarIsOpen={false}
        >
          {leftIcon && (
            <ListItemIcon className={classes.icon}>
              {cloneElement(leftIcon, {
                titleAccess: primaryText,
              })}
            </ListItemIcon>
          )}
        </MenuItem>
      );
    };

    return (
      <Tooltip title={primaryText} placement="right">
        {renderMenuItem()}
      </Tooltip>
    );
  }
);

interface Props {
  leftIcon?: ReactElement;
  primaryText?: ReactNode;
}

export type MenuItemLinkProps = Props &
  NavLinkProps &
  MenuItemProps<'li', { button?: true }>;

MenuItemLink.propTypes = {
  leftIcon: PropTypes.element,
  onClick: PropTypes.func,
  primaryText: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default MenuItemLink;