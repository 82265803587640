import gql from 'graphql-tag';

import { Category } from '../fragments/Category';

export const getCategory = gql`
  query getCategory($id: ID!) {
    data: getCategory(id: $id) {
      ...Category
    }
  }
  ${Category}
`;
