import React from 'react';
import { Edit } from 'react-admin';

import PostForm from '../components/PostForm';

const AdvertisingEdit = (props: any) => {
  return (
    <Edit {...props}>
      <PostForm title="resources.advertising.create" />
    </Edit>
  );
};

export default AdvertisingEdit;
