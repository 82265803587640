import gql from "graphql-tag";

import { Pagination } from "../fragments/Pagination";
import { Country } from "../fragments/Country";

export const listCountries = gql`
  query listCountries(
    $filter: ListCountriesFilterInput
    $sort: ListCountriesSortInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listCountries(
      filter: $filter,
      sort: $sort,
      after: $after,
      before: $before,
      first: $first,
      last: $last,
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        ...Country
      }
    }
  }
  ${Pagination}
  ${Country}
`;
