import gql from "graphql-tag";

import { Group } from "../fragments/Group";

export const editGroup = gql`
  mutation updateChatGroup(
    $id: ID!
    $title: String
    $description: String
    $interestIds: [ID!]
    $image: Upload
  ) {
    updateChatGroup(
      id: $id
      input: {
        title: $title,
        description: $description,
        interestIds: $interestIds,
        image: $image
      }
    ) {
      ...Group
    }
  }
  ${Group}
`;
