import React, {useEffect} from "react";
import {useQuery} from "react-admin";
import {useState} from "react";
import { COLORS_STORY_TYPE, formatDate, RADIAN, useDebounce} from "../utils";
import DatePicker from "react-date-picker";
import {useStyles} from "./styles";
import { Cell, Pie, PieChart} from "recharts";

import TextField from "@material-ui/core/TextField";


export const StoryGraph = () => {
    const classes = useStyles();
    const [dateFrom,setDateFrom]= useState<Date>(()=>{
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 12);
        return currentDate;
    })
    const [dateTo,setDateTo]= useState<Date>(new Date());

    const [interests,setInterests] = useState([]);
    const [selectInterest, setSelectInterest] = useState<string | null>(null);

    const [searchInterest, setSearchInterest] = useState<string | null>(null);
    const debouncedSelectInterest = useDebounce(searchInterest, 500);


    const { data, isLoading, error } = useQuery({
        type: 'getOne',
        resource: 'storyGraph',
        payload: { filter: {
                dateFrom: formatDate(dateFrom), dateTo: formatDate(dateTo),
                interestIds: [selectInterest]
            }}
    });



    const { data: listInterest} = useQuery({
        type: 'getList',
        resource: 'interests',
        payload: { filter: {  label: debouncedSelectInterest}, pagination: { page: 1, perPage: 50 } }
    });


    useEffect(()=>{
        if(listInterest ){
            const newArr = [];
            for (const property in listInterest) {
                newArr.push(listInterest[property]);
            }
            if(newArr.length){
                setInterests([...newArr]);
            }
        }
    },[listInterest])

    // console.log('StoryGraph',data);

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <>
                <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${name}`}
                </text>
                <text x={x} y={y + 18} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            </>
        );
    };


    return (
        <div className={classes.wrapDashboard}>
            <div className={classes.titleRow}>
                <h2 className={classes.title}>Story type graph</h2>
            </div>
            <div className={classes.wrapBlock}>
                <div className={classes.pieChartWrap}>
                    <div className={classes.filtersRows}>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date From</p>
                            <DatePicker locale={"en-EN"} value={dateFrom} onChange={(date: Date) => setDateFrom(date)} maxDate={new Date()} />
                        </div>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date To</p>
                            <DatePicker locale={"en-EN"} value={dateTo} onChange={(date: Date) => setDateTo(date)} maxDate={new Date()} />
                        </div>
                    </div>
                    <div>
                        {
                            data && Object.keys(data).length != 0 && !isLoading &&
                            <PieChart width={480} height={400}>
                                <Pie
                                    data={Object.entries(data).map(([name, value]) => ({ name, value }))}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ name, value, ...rest }) => renderCustomizedLabel({ name, value, ...rest })}
                                    outerRadius={170}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {Object.entries(data).map((entry, index) => {
                                        const matchingColor = COLORS_STORY_TYPE.find(story => story.name === entry[0]);
                                        return <Cell key={`cell-${index}`} fill={matchingColor?.color} />;
                                    })}
                                </Pie>
                            </PieChart>
                        }
                        {
                            data && Object.keys(data).length == 0 && !isLoading &&
                            <div>no data (change filters)</div>
                        }
                    </div>
                </div>
                <div className={classes.filters}>
                <div className={classes.filterBorderWrap}>
                    <p className={classes.labelForInputs}>Select health interest</p>
                    <div className={classes.filters}>
                        <TextField
                            size="small"
                            className={classes.filterSearch}
                            value={searchInterest}
                            onChange={(e:any)=> setSearchInterest(e.target.value)}
                            label="Seach interest"
                            variant="outlined"
                        />
                    </div>
                    <div className={classes.searchResults}>
                        <div onClick={()=> setSelectInterest(null)}
                             className={classes.searchResultItem}
                             style={{background:  !selectInterest ? '#0088FE' : 'transparent' }}
                        >none</div>
                        {
                            interests?.length > 0 && interests.map((item: {id:string; label: string})=>
                                <div onClick={()=> setSelectInterest(item?.id)}
                                     className={classes.searchResultItem}
                                     key={item?.id}
                                     style={{background: item?.id == selectInterest ? '#0088FE' : 'transparent' }}
                                >
                                    {item?.label}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            </div>


        </div>
    )
}
