import gql from "graphql-tag";

import { Interest } from "../fragments/Interest";

export const getInterest = gql`
  query getInterest($id: ID!) {
    data: getInterest(id: $id) {
      ...Interest
    }
  }
  ${Interest}
`;
