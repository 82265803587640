import CategoryIcon from '@material-ui/icons/Category';

import CategoriesList from './CategoriesList';
import CategoryEdit from './CategoryEdit';

export default {
  list: CategoriesList,
  edit: CategoryEdit,
  icon: CategoryIcon,
};
