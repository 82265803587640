import React from 'react';

const Share = () => {
  return (
    <svg
      width="22px"
      height="20px"
      viewBox="0 0 22 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.13896 20c-.05994 0-.14986 0-.2098-.02957-.4496-.0887-.74932-.41393-.80927-.85742C.02997 18.52168 0 17.93035 0 17.30946c0-6.3272 4.73569-11.73784 11.05995-12.62483V1.28449c0-.50262.29972-.97569.80926-1.18265.47956-.20696 1.04905-.0887 1.40872.2661l8.27248 8.16031c.29973.29567.44959.65046.44959 1.06439 0 .41393-.14986.76873-.44959 1.06439l-8.30245 8.16032c-.35968.35479-.92916.47306-1.40872.26609-.47957-.20696-.80927-.65046-.80927-1.18265v-3.7845c-3.71662.23654-7.07357 2.24705-9.0218 5.38108C1.82834 19.8226 1.49864 20 1.13896 20zM12.55858 1.78712v3.04534c0 .65046-.50953 1.21222-1.16893 1.30092-5.66485.73916-9.92099 5.55847-9.92099 11.17608v.3548c2.30791-3.01577 5.84469-4.87845 9.71118-5.05585.35967-.02957.68937.11827.95912.3548.26976.23653.41962.59132.41962.94612v3.5184l7.91281-7.80552v-.02957l-7.91281-7.80552z"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Share;
