import React from 'react';
import { Edit } from 'react-admin';

import PromotionForm from './PromotionForm';

const PromotionEdit = (props: any) => {
  return (
    <Edit {...props}>
      <PromotionForm title="resources.promotions.edit" />
    </Edit>
  );
};

export default PromotionEdit;
