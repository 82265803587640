import gql from "graphql-tag";

export const Interest = gql`
  fragment Interest on Interest {
    id
    label
    createdAt
    status
  }
`;
