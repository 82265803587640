import gql from 'graphql-tag';
import {GetCheckInStatisticFilterInput} from "../../../types/graphql-global-types";

export const getCheckInStatistic = gql`
    query getCheckInStatistic($filter: GetCheckInStatisticFilterInput!) {
        data: getCheckInStatistic(
            filter: $filter
        )
    }
`;
