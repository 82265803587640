import gql from "graphql-tag";

import { Interest } from "./Interest";
import { Attachment } from "./Attachment";

export const typename = "Group";

export const Group = gql`
  fragment Group on ChatGroup {
    description
    id
    image {
      ...Attachment
    }
    interestIds
    interests {
      nodes {
        ...Interest
      }
    }
    joined
    kind
    title
    usersCount
    createdAt
  }
  ${Interest}
  ${Attachment}
`;
