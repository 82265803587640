import decode from 'jwt-decode';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
  userEmail: string;
}

export class Session {
  key = 'session';

  async isExpired() {
    const token = await this.getAccessToken();

    if (!token) {
      return false;
    }

    const { exp } = decode(token);

    return exp < Math.round(Date.now() / 1000) + 60;
  }

  async getAccessToken() {
    return window.localStorage.getItem(`${this.key}-access`);
  }

  async getRefreshToken() {
    return window.localStorage.getItem(`${this.key}-refresh`);
  }

  async update(data: Tokens) {
    // @TODO: Looks like setMultiple method is broken
    window.localStorage.setItem(`${this.key}-access`, data.accessToken);
    window.localStorage.setItem(`${this.key}-refresh`, data.refreshToken);
    if (data.userEmail) {
      window.localStorage.setItem('userEmail', data.userEmail);
    }
  }

  async delete() {
    window.localStorage.removeItem(`${this.key}-access`);
    window.localStorage.removeItem(`${this.key}-refresh`);
    window.localStorage.removeItem('userEmail');
  }
}

export default new Session();
