import gql from 'graphql-tag';



export const getUsersActivityStatistic = gql`
    query getUsersActivityStatistic(
        $filter: GeneralStatisticFilterInput
    ) {
        data: getUsersActivityStatistic(
            filter: $filter
        ) 
    }
`;
