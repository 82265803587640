import React, {useEffect} from "react";
import {useQuery} from "react-admin";
import {useState} from "react";
import {useStyles} from "./styles";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import {Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {formatDate} from "../utils";
import DatePicker from "react-date-picker";

const conditions = [
    {
        id: 'general',
        label: 'General'
    },
    {
        id: 'ibs_severity',
        label: 'Irritable Bowel Syndrome (IBS) Severity'
    },
    {
        id: 'well_being_index',
        label: 'W.H.O. MENTAL HEALTH'
    },
    {
        id: 'gerd',
        label: 'Gastro-esophageal reflux disease'
    },
    {
        id: 'als',
        label: 'Amyotrophic Lateral Sclerosis (ALS)'
    },
    {
        id: 'cat',
        label: 'CAT'
    },
    {
        id: 'dementia',
        label: 'Dementia'
    },{
        id: 'asthma',
        label: 'Asthma'
    },{
        id: 'edss',
        label: 'Disability'
    },
    {
        id: 'cage',
        label: 'CAGED AID:Drugs'
    },{
        id: 'ulcerative_colitis',
        label: 'Ulcerative colitis'
    },{
        id: 'ptsd',
        label: 'PTSD'
    },{
        id: 'gad7',
        label: 'Generalized Anxiety Disorder'
    },
    {
        id: 'audit',
        label: 'Alcohol Use Disorder'
    },
    {
        id: 'adhd',
        label: 'ADHD'
    },{
        id: 'depression',
        label: 'Depression'
    },
]


export const CheckInGraph = () => {
    const classes = useStyles();
    const [entityScale, setEntityScale] = useState<string>('month');

    const [dateFrom,setDateFrom]= useState<Date>(()=>{
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 12);
        return currentDate;
    })
    const [dateTo,setDateTo]= useState<Date>(new Date());

    const [conditionType, setConditionType] = useState<string>('general');


    const { data, isLoading, error } = useQuery({
        type: 'getOne',
        resource: 'checkInGraph',
        payload: { filter: {
                scale: entityScale, dateFrom: formatDate(dateFrom), dateTo: formatDate(dateTo),
                type: conditionType
            }}
    });


    // console.log('userGraph',data);


    return (
        <div className={classes.wrapDashboard}>
            <div className={classes.titleRow}>
                <h2 className={classes.title}>Health Trackers graph</h2>
            </div>
            <div className={classes.wrapBlock}>
                <div className={classes.diagramWrapper}>
                    <div className={classes.filtersRows}>
                        <div>
                            <p className={classes.labelForInputs}>Select scale of time</p>
                            <Select
                                className={classes.inputSelect}
                                defaultValue={'month'}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={entityScale}
                                label="Select scale of time"
                                onChange={(event)=>setEntityScale(event.target.value as string)}
                            >
                                <MenuItem value={'day'}>day</MenuItem>
                                <MenuItem value={'month'}>month</MenuItem>
                                <MenuItem value={'year'}>year</MenuItem>
                            </Select>
                        </div>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date From</p>
                            <DatePicker locale={"en-EN"} value={dateFrom} onChange={(date: Date) => setDateFrom(date)} maxDate={new Date()} />
                        </div>
                        <div className={classes.filterWrapItem}>
                            <p className={classes.labelForInputs}>Date To</p>
                            <DatePicker locale={"en-EN"} value={dateTo} onChange={(date: Date) => setDateTo(date)} maxDate={new Date()} />
                        </div>
                    </div>
                    <div>
                        {
                            data && Object.keys(data).length != 0 && !isLoading &&
                            <LineChart
                                width={800}
                                height={400}
                                data={Object.entries(data).reverse().map(([date, activity]) => ({ date, activity }))}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 35
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tick={{ fontSize: 14, fontFamily: 'Arial' }} />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="activity"
                                    stroke="#0088FE"
                                    activeDot={{ r: 4 }}
                                />
                            </LineChart>
                        }
                        {
                            data && Object.keys(data).length == 0 && !isLoading &&
                            <div>no data (change filters)</div>
                        }
                    </div>
                </div>
                <div className={classes.filters}>
                    <div className={classes.filterBorderWrap}>
                        <p className={classes.labelForInputs}>Select condition type</p>
                        <div className={classes.searchResults}>
                            {
                                conditions?.length > 0 && conditions.map((item: {id:string; label: string})=>
                                    <div onClick={()=> setConditionType(item?.id)}
                                         className={classes.searchResultItem}
                                         key={item?.id}
                                         style={{background: item?.id == conditionType ? '#0088FE' : 'transparent' }}
                                    >
                                        {item?.label}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
