import React from "react";
import {
  Create,
} from "react-admin";

import GroupForm from "./GroupForm";

const GroupCreate = (props: any) => {
  return (
    <Create {...props}>
      <GroupForm title="resources.groups.create" />
    </Create>
  );
};

export default GroupCreate;
