import gql from 'graphql-tag';

export const getDashboard = gql`
  query getDashboard {
    data: getDashboard {
      allUsersCount
      monthlyUsersCount
      weeklyUsersCount
      clickEmotionsUserCount
      interests {
        label
        interestUsersCount
      }
      eachEmotionClickedUserCount {
          anxious
          feelingBetter
          feelingStrong
          inNeedOfSupport
          lessStressed
          makingIt
      }
    }
  }
`;
