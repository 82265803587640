import gql from "graphql-tag";

import { Country } from "./Country";
import { Attachment } from "./Attachment";
import { Interest } from "./Interest";

export const typename = "PublicUser";

export const PublicUser = gql`
  fragment PublicUser on PublicUser {
    id
    gender
    fullName
    aboutMe
    education
    age
    showAge
    showInterests
    showContact
    profileCompleted
    createdAt
    updatedAt
    followersCount
    followingCount
    mainInterestIds
    mainInterests {
      nodes {
        ...Interest
      }
    }
    interestIds
    interests {
      nodes {
        ...Interest
      }
    }
    avatar {
      ...Attachment
    }
    country {
      ...Country
    }
  }
  ${Interest}
  ${Country}
  ${Attachment}
`;
