import React from "react";
import {
  Edit,
  TextInput,
  required,
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from "../components/SimpleForm";

const useStyles = makeStyles({
  input: {
    width: '50%',
  },
});

const InterestEdit = (props: any) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm title="resources.interests.edit">
        <TextInput
          source="label"
          label="resources.interests.fields.health_interest"
          validate={required()}
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
};

export default InterestEdit;
