import gql from 'graphql-tag';

import { Post } from '../fragments/Post';

export const createPost = gql`
  mutation createPost(
    $title: String
    $content: String
    $shortDescription: String
    $interestIds: [ID!]!
    $attachments: [Upload!]
    $introImages: [Upload!]
    $ownerAvatar: Upload
    $ownerName: String
    $kind: String!
    $pickedByEditor: Boolean
    $categoryId: ID
  ) {
    data: createPost(
      input: {
        title: $title
        content: $content
        shortDescription: $shortDescription
        kind: $kind
        interestIds: $interestIds
        introImages: $introImages
        attachments: $attachments
        ownerAvatar: $ownerAvatar
        ownerName: $ownerName
        pickedByEditor: $pickedByEditor
        categoryId: $categoryId
      }
    ) {
      ...Post
    }
  }
  ${Post}
`;
