import React from 'react';
import { Edit, TextInput, required, ImageInput, ImageField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from '../components/SimpleForm';

const iconSize = 40;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '50%',
    height: '40%',
    minHeight: 300,
    minWidth: 500,
    backgroundColor: 'rgba(193, 206, 222, .3)',
    borderRadius: 4,
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      top: 48,
      position: 'relative',
      left: 13,
    },
  },
  removeButton: {
    display: 'inline-block',
    '& button': {
      position: 'absolute',
      top: 17,
      left: '23%',
      maxWidth: iconSize,
      maxHeight: iconSize,
      minWidth: iconSize,
      minHeight: iconSize,
      zIndex: 2,
      backgroundColor: 'rgba(255,255,255,.7)',
      padding: 0,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: 0,
    borderRadius: 4,
  },
  dropZone: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,.7)',
    padding: '10px 20px',
    borderRadius: 20,
    margin: 10,
    '& p': {
      margin: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
  },
  input: {
    width: '50%',
  },
});

const CategoryEdit = (props: any) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm title="resources.categories.edit">
        <TextInput
          source="title"
          label="resources.categories.fields.title"
          validate={required()}
          className={classes.input}
        />
        <TextInput
          source="description"
          label="resources.categories.fields.description"
          multiline
          className={classes.input}
          rows="6"
        />
        <ImageInput
          source="image"
          label="resources.categories.fields.image"
          accept="image/*"
          labelSingle="Select image"
          classes={classes}
        >
          <ImageField source="thumbUrl" title="title" classes={classes} />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;
