import gql from "graphql-tag";

export const Support = gql`
  fragment Support on Support {
    createdAt
    id
    supportableId
    supportableType
    updatedAt
    userId
  }
`;
