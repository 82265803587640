import React, { useMemo } from 'react';
import { useQuery, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import UsersCount from './UsersCount';

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    padding: '30px 5px 10px',
  },
  interestsContainer: {
    border: '1px solid rgba(28,50,89,0.06)',
    borderRadius: 6,
    boxShadow: '0 4px 9px 0 rgba(28,50,89,0.03)',
    marginBottom: '1em',
  },
  interestsTitle: {
    color: '#1B2942 !important',
    fontSize: 16,
    padding: '20px 30px',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '0 30px 5px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '0.5px dashed rgba(140, 160, 179, .76)',
  },
  rowText: {
    color: '#2C2F49 !important',
    fontSize: 14,
    paddingBottom: 7,
  },
  percent: {
    height: 7,
    backgroundColor: '#9CDEEC',
    borderRadius: '0 55px 55px 0',
    position: 'relative',
    top: -8,
  },
});

const Dashboard = () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { data = {} } = useQuery({
    type: 'getOne',
    resource: 'dashboard',
  });
  const {
    allUsersCount,
    monthlyUsersCount,
    weeklyUsersCount,
    interests = [],
    clickEmotionsUserCount,
    eachEmotionClickedUserCount,
  } = data;
  const getPercent = (number) => ((100 * number) / allUsersCount).toFixed(1);

  const emotionsKeys = useMemo(() => {
    const result = Object.keys(eachEmotionClickedUserCount || {});
    result.pop();

    return result;
  }, [eachEmotionClickedUserCount]);

  const totalEmotionsCount = useMemo(() => {
    const result = emotionsKeys.reduce((acc, key) => {
      acc += eachEmotionClickedUserCount[key];

      return acc;
    }, 0);

    return result;
  }, [eachEmotionClickedUserCount, emotionsKeys]);

  const getEmotionPercent = (number) =>
    ((100 * number) / totalEmotionsCount).toFixed(1);

  return (
    <>
      <Typography variant="h5" color="inherit" className={classes.title}>
        {translate('pos.dashboard.name')}
      </Typography>
      <div>
        <div className={classes.flex}>
          <UsersCount value={allUsersCount} text="pos.dashboard.all_users" />
          <UsersCount
            value={monthlyUsersCount}
            text="pos.dashboard.monthly_users"
            percent={getPercent(monthlyUsersCount)}
          />
          <UsersCount
            value={weeklyUsersCount}
            text="pos.dashboard.weekly_users"
            percent={getPercent(weeklyUsersCount)}
          />
          <UsersCount
            value={clickEmotionsUserCount}
            text="pos.dashboard.click_emotions_count"
          />
          {/*<UsersCount*/}
          {/*  value={changedPrimaryDiagnosisUserCount}*/}
          {/*  text="pos.dashboard.change_primary_diagnosis"*/}
          {/*/>*/}
          {/*<UsersCount*/}
          {/*  value={`${putPrimaryDiagnosisUserCount || '0'}/${*/}
          {/*    allUsersCount || '0'*/}
          {/*  }`}*/}
          {/*  text="pos.dashboard.put_primary_diagnosis"*/}
          {/*/>*/}
        </div>

        <Card className={classes.interestsContainer}>
          <Typography className={classes.interestsTitle}>
            {translate('pos.dashboard.health_interests')}
          </Typography>
          <div className={classes.table}>
            {interests.map(({ label, interestUsersCount }, index) => {
              const percent = `${getPercent(interestUsersCount)}%`;
              const gridRow = (index + 1) % 10 || 10;
              const gridColumn = Math.ceil((index + 1) / 10);
              return (
                <div className={classes.row} style={{ gridColumn, gridRow }}>
                  <div className={classes.textContainer}>
                    <Typography className={classes.rowText}>{label}</Typography>
                    <Typography className={classes.rowText}>
                      {percent}
                    </Typography>
                  </div>
                  <div className={classes.percent} style={{ width: percent }} />
                </div>
              );
            })}
          </div>
        </Card>

        <Card>
          <Typography className={classes.interestsTitle}>
            {translate('pos.dashboard.emotions')}
          </Typography>
          <div className={classes.table}>
            {emotionsKeys.map((emotion, index) => {
              const percent = (getEmotionPercent(eachEmotionClickedUserCount[emotion])) === 'NaN' ? '0%' :`${getEmotionPercent(
                eachEmotionClickedUserCount[emotion],
              )}%`;
              const gridRow = (index + 1) % 10 || 10;
              const gridColumn = Math.ceil((index + 1) / 10);

              const capitalizedEmotionName = `${emotion
                .charAt(0)
                .toUpperCase()}${emotion.slice(1)}`;

              return (
                <div className={classes.row} style={{ gridColumn, gridRow }}>
                  <div className={classes.textContainer}>
                    <Typography className={classes.rowText}>
                      {capitalizedEmotionName}
                    </Typography>
                    <Typography className={classes.rowText}>
                      {percent}
                    </Typography>
                  </div>
                  <div className={classes.percent} style={{ width: percent }} />
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Dashboard;
