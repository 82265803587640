import React from 'react';
import { useTranslate } from 'react-admin';

import {EntitiesGraph} from "../components/graphs/FirstEntitiesGraph/EntitiesGraph";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {EmotionsGraph} from "../components/graphs/EmotionsGraph/EmotionGraph";
import {StoryGraph} from "../components/graphs/StoryTypesGraph/StoryGraph";
import {UserStatisticGraph} from "../components/graphs/UserGraph/UserStatisticGraph";
import {CheckInGraph} from "../components/graphs/CheckInGraph/CheckInGraph";


const useStyles = makeStyles({
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        padding: '30px 5px 10px',
    },
    interestsContainer: {
        border: '1px solid rgba(28,50,89,0.06)',
        borderRadius: 6,
        boxShadow: '0 4px 9px 0 rgba(28,50,89,0.03)',
        marginBottom: '1em',
    },
    interestsTitle: {
        color: '#1B2942 !important',
        fontSize: 16,
        padding: '20px 30px',
    },
    table: {
        display: 'grid',
        gridTemplateColumns: '3fr 3fr 3fr',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: '0 30px 5px',
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: '0.5px dashed rgba(140, 160, 179, .76)',
    },
    rowText: {
        color: '#2C2F49 !important',
        fontSize: 14,
        paddingBottom: 7,
    },
    percent: {
        height: 7,
        backgroundColor: '#9CDEEC',
        borderRadius: '0 55px 55px 0',
        position: 'relative',
        top: -8,
    },
    graphsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    graphWrapRight: {
        width: 'calc(50% - 15px)',
        paddingLeft: 15,
    },
    graphWrapLeft: {
        width: 'calc(50% - 15px)',
        paddingRight: 15,
    }
});

export const NewDashboard = () => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <>
            <Typography variant="h5" color="inherit" className={classes.title}>
                New {translate('pos.dashboard.name')}
            </Typography>
            <div>
                <EntitiesGraph />
            </div>
            <EmotionsGraph />
            <StoryGraph />
            <UserStatisticGraph />
            <CheckInGraph />
        </>
    );
};
