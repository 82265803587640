import {makeStyles} from "@material-ui/core/styles";
import {theme} from "../../layout";

export const useStyles = makeStyles({

    articleWrap: {
        paddingTop: 50,
        marginBottom: 30
    },
    titleWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 30
    },
    title: {
        fontSize: 28,
        fontWeight: 600,
        lineHeight: 1.3
    },
    list: {
       padding: 20,
        background: '#fff'
    },
    listItemHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 20
    },
    listItem: {
        padding: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        marginBottom: 20,
        alignItems: 'center',
        '&:nth-child(even)': {
            background: '#F4F6FC'
        }
    },
    headerGridId: {

    },
    moreIcon: {
       marginRight: 5
    },
    headerGrid: {
        fontSize: 16
    },
    valueGrid: {
        fontSize: 14
    },
    headerThID: {
        maxWidth: 200,
        width: '100%',
        marginRight: 20
    },
    headerThTitle: {
        maxWidth: 320,
        width: '100%',
        marginRight: 20
    },
    headerThAttachment: {
        maxWidth: 100,
        width: '100%',
        marginRight: 20
    },
    headerThTotal: {
        maxWidth: 170,
        width: '100%',
        marginRight: 20
    },
    headerThInterests: {
        maxWidth: 340,
        width: '100%',
        marginRight: 20
    },
    headerThCreatedAt: {
        maxWidth: 130,
        width: '100%',
        marginRight: 20
    },
    filters: {
        display: 'flex',
        marginBottom: 20
    },
    filterSearch: {
        width: 300,
    },
    paginationWrap: {
        display: 'flex',
        marginBottom: 20,
        justifyContent: 'space-between'
    },
    pagination: {
        display: 'flex'
    },
    paginationWrapList: {
        display: 'flex',
        marginRight: 15,
        marginLeft: 15
    },
    paginationItem:{
        border: '1px solid #000',
        borderRadius: 4,
        padding: '3px 5px',
        marginRight: 5,
        cursor: 'pointer'
    },
    paginationBtns: {
        marginRight: 15,
        display: 'flex',
        height: 30,
        alignItems: 'center',
        padding: 10,
        border: '1px solid #000',
        borderRadius: 4,
    },
    rowsPerPage: {
        marginRight: 15,
        display: 'flex',
        height: 30,
        alignItems: 'center',
        padding: 10,
    },
    rowsPerPageTitle: {
        marginRight: 15
    },
    createButton: {
        borderRadius: 20,
        color: '#FFFFFF',
        backgroundColor: '#8EDAEA',
        padding: '5px 20px',
        boxShadow: 'none',
        marginLeft: 20,
        width: 200,
        '&:hover': {
            backgroundColor: 'rgb(79, 128, 142)',
        },
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.09)',
            color: '#FFFFFF',
        },
        '& span': {
            textTransform: 'none',
            whiteSpace: 'nowrap',
            fontSize: 16,
        },
    },
    actionsButtons: {
        display: 'flex',
        alignItems: 'center'
    },
    delButton: {
        width: 22,
        height: 22,
        cursor: 'pointer',
        marginRight: 15
    }
});
