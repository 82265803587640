import React, { FC } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate } from 'react-admin';

import users from '../users';
import groups from '../groups';
import advertising from '../advertising';
import articles from '../articles';
import categories from '../categories';
import interests from '../interests';
import spamWords from '../spamWords';
import promotions from '../promotions';
import answers from '../answers';
import posts from '../posts';

import MenuItemLink from './MenuItemLink';

interface Props {
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, logout }) => {
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  return (
    <div style={{width: 'fit-content'}}>
      <MenuItemLink
        onClick={onMenuClick}
        to="/"
        primaryText={translate('ra.page.dashboard')}
        leftIcon={<DashboardIcon />}
        exact
      />
        <MenuItemLink
            onClick={onMenuClick}
            to="/newdashboard"
            primaryText={'New DashBoard'}
            leftIcon={<DashboardIcon />}
        />
      <MenuItemLink
        to="/users"
        leftIcon={<users.icon />}
        onClick={onMenuClick}
        primaryText={translate('pos.menu.users')}
      />
      <MenuItemLink
        to="/groups"
        primaryText={translate('pos.menu.groups')}
        leftIcon={<groups.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/advertising"
        primaryText={translate('pos.menu.advertising')}
        leftIcon={<advertising.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/articles"
        primaryText={translate('pos.menu.articles')}
        leftIcon={<articles.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/posts"
        primaryText={'Feed'}
        leftIcon={<posts.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/categories"
        primaryText={translate('pos.menu.categories')}
        leftIcon={<categories.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/interests"
        primaryText={translate('pos.menu.interests')}
        leftIcon={<interests.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/spams"
        primaryText={translate('pos.menu.spamWords')}
        leftIcon={<spamWords.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/promotions"
        primaryText={translate('pos.menu.promotions')}
        leftIcon={<promotions.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to="/answers"
        primaryText={translate('pos.menu.answers')}
        leftIcon={<answers.icon />}
        onClick={onMenuClick}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
