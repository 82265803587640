import React, { FC } from "react";
import { Record } from "ra-core";
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps } from "../types";

const useStyles = makeStyles({
  root: {
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
    width: '10vw',
  }
});

const EmailField: FC<FieldProps<Record>> = ({ record }) => {
  const classes = useStyles();
  return (
    record ? (
      <div className={classes.root}>
        {record.email}
      </div>
    ) : null
  );
}

EmailField.defaultProps = {
  sortable: false,
};

export default EmailField;
