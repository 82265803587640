import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  'ra.navigation.prev': 'Previous',
  'resources.articles.name': 'Article |||| Articles',
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    export: 'Export to Excel',
    select_photo: 'Select photo *',
    photo_not_required: 'Select photo',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      name: 'Dashboard',
      all_users: 'All users',
      monthly_users: 'New users this month',
      weekly_users: 'New users this week',
      health_interests: 'Health interests',
      put_primary_diagnosis: 'Users that put a primary diagnosis',
      change_primary_diagnosis: 'Users that changed their primary diagnosis',
      click_emotions_count: 'Users that click emotions',
      emotions: 'Emotions',
    },
    menu: {
      users: 'Users',
      groups: 'Groups',
      advertising: 'Advertising',
      articles: 'Articles',
      categories: 'Categories',
      interests: 'Health interests',
      spamWords: 'Spam words',
      promotions: 'Promotions',
      answers: 'Answers',
      posts: 'Posts',
    },
  },
  resources: {
    users: {
      name: 'User |||| Users',
      fields: {
        user_id: 'User ID',
        name: 'Name',
        email: 'Email',
        mobile: 'Mobile',
        country: 'Country',
        user_type: 'User type',
        main_interest: 'Main interest',
        gender: 'Gender',
        health_interests: 'Health interests',
        age_from: 'Age From',
        age_to: 'Age To',
        user_type: 'User type',
        other_interests: 'Other interests',
        date: 'Creation date',
      },
      search: 'Search people',
      delete_tooltip: 'Delete user',
      edit_tooltip: 'Edit user',
      delete_title: 'Are you sure you want to delete this user?',
      delete_content: '',
      block_title: 'Are you sure you want to block this user?',
      block_content: '',
    },
    groups: {
      name: 'Group |||| Groups',
      fields: {
        group_id: 'Group ID',
        group_name: 'Group Name',
        health_interests: 'Health interests',
        members: 'Members',
        date: 'Creation date',
        description: 'Group Description',
      },
      search: 'Search group',
      create: 'Group creation',
      edit: 'Edit group',
      create_button: 'Create group',
      upload_photo: 'Please upload group photo',
      delete_tooltip: 'Delete group',
      edit_tooltip: 'Edit group',
      delete_title: 'Are you sure you want to delete this group?',
      delete_content: 'All data and members will be permanently deleted.',
    },
    posts: {
      name: 'Post |||| Posts',
      fields: {
        title: 'Title',
        id: 'ID',
        health_interests: 'Health interests',
        date: 'Creation date',
        title: 'Post title',
        engagement_total_count: 'Engagement Total',
        text: 'Post text',
        author: 'Author',
        owner_name: 'Owner name',
        short_description: 'Short description',
        attachment: 'Attachment',
        upload_photo: 'Please upload post owner logo',
        picked: "Editor's pick",
        category: 'Category ID',
        likes_count: 'Likes count',
        informs_count: 'Informs count',
        supports_count: 'Supports count',
        shares_count: 'Shares count',
        story_types: 'Story types',
      },
      search: 'Search post',
      create: 'Post creation',
      edit: 'Edit post',
      create_button: 'Create post',
      delete_tooltip: 'Delete post',
      edit_tooltip: 'Edit post',
      delete_title: 'Are you sure you want to delete this post?',
      delete_content: '',
      pin_tooltip: 'Pin post',
      pin_success: 'The post has been %{pinned} successfully',
    },
    articles: {
      name: 'Article |||| Articles',
      fields: {
        title: 'Title',
        id: 'ID',
        health_interests: 'Health interests',
        date: 'Creation date',
        title: 'Article title',
        engagement_total_count: 'Engagement Total',
        text: 'Article text',
        author: 'Author',
        owner_name: 'Owner name',
        short_description: 'Short description',
        attachment: 'Attachment',
        upload_photo: 'Please upload post owner logo',
        picked: "Editor's pick",
        category: 'Category ID',
      },
      search: 'Search article',
      create: 'Article creation',
      edit: 'Edit article',
      create_button: 'Create article',
      delete_tooltip: 'Delete article',
      edit_tooltip: 'Edit article',
      delete_title: 'Are you sure you want to delete this article?',
      delete_content: '',
      pin_tooltip: 'Pin article',
      pin_success: 'The article has been %{pinned} successfully',
    },
    advertising: {
      name: 'Advertising',
      create: 'Advertising creation',
      edit: 'Edit advertising',
      fields: {
        owner_name: 'Owner name',
        owner_avatar: 'Select post owner logo *',
        title: 'Advertising title',
        text: 'Advertising text',
        upload_photo: 'Please upload post owner logo',
        id: 'ID',
        health_interests: 'Health interests',
        date: 'Creation date',
        title: 'Advertising title',
        engagement_total_count: 'Engagement Total',
        short_description: 'Short description',
        attachment: 'Attachment',
        picked: "Editor's pick",
        category: 'Category ID',
      },
      delete_tooltip: 'Delete advertising',
      edit_tooltip: 'Edit advertising',
      delete_title: 'Are you sure you want to delete this advertising?',
      delete_content: '',
      search: 'Search advertising',
      create_button: 'Create advertising',
      pin_tooltip: 'Pin advertising',
      pin_success: 'The advertising has been %{pinned} successfully',
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        id: 'ID',
        title: 'Title',
        description: 'Description',
        date: 'Creation date',
        image: 'Image',
      },
      edit: 'Edit category',
      edit_tooltip: 'Edit category',
    },
    interests: {
      name: 'Health Interest |||| Health Interests',
      fields: {
        title: 'Title',
        id: 'ID',
        health_interest: 'Health interest',
        date: 'Creation date',
        status: 'Status',
      },
      search: 'Search health interest',
      create: 'Health interest creation',
      create_button: 'Create health interest',
      delete_tooltip: 'Delete interest',
      edit_tooltip: 'Edit interest',
      delete_title: 'Are you sure you want to delete this interest?',
      delete_content: '',
      edit: 'Edit health interest',
    },
    spams: {
      name: 'Spam words',
      fields: {
        word: 'Word',
      },
      create: 'Spam word creation',
      create_button: 'Create spam word',
      delete_tooltip: 'Delete spam word',
      delete_title: 'Are you sure you want to delete this spam word?',
      delete_content: '',
    },
    promotions: {
      name: 'Promotion |||| Promotions',
      fields: {
        id: 'ID',
        title: 'Title',
        kind: 'Kind',
        date: 'Date',
        picture: 'Picture',
        selectPicture: 'Select picture *',
        answers: 'Answers',
      },
      search: 'Search promotion',
      create: 'Promotion creation',
      edit: 'Edit promotion',
      create_button: 'Create promotion',
      delete_tooltip: 'Delete promotion',
      edit_tooltip: 'Edit group',
      delete_title: 'Are you sure you want to delete this promotion?',
      delete_content: '',
    },
    answers: {
      name: 'Answers',
      fields: {
        id: 'ID',
        answer: 'Answer',
        author: 'Author',
        promotion: 'Promotion',
      },
    },
  },
  auth: {
    email: 'Email',
    title: 'Login to Healthread Admin',
    subtitle: "We're so excited to see you again!",
  },
};
