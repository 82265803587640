import gql from "graphql-tag";

import { Interest } from "../fragments/Interest";

export const editInterest = gql`
  mutation updateInterest(
    $id: ID!
    $label: String!
  ) {
    updateInterest(
      id: $id
      input: {
        label: $label,
      }
    ) {
      ...Interest
    }
  }
  ${Interest}
`;
