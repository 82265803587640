import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles({
    wrapDashboard: {
        padding: 15,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#000',
        borderRadius: 10,
        marginBottom: 20
    },
    filtersRows: {
        marginBottom: 10,
        display: 'flex',
    },
    titleRow: {
        marginBottom: 20,
        display: 'flex',
    },
    title: {
        fontSize: 20,
    },
    inputSelect: {
        width: 200,
        marginBottom: 10,
        marginRight: 20,
        '& p': {
            display: 'none',
        },
    },
    labelForInputs: {
        fontSize: 14,
        marginBottom: 4
    },
    filterWrapItem: {
        marginRight: 12
    },
    searchInterest: {
        width: 270,
    },
    wrapBlock: {
        display: 'flex',
        width: '100%'
    },
    filters: {
        display: 'flex',
        marginBottom: 20
    },
    filterSearch: {
        width: 240,
    },
    filterBorderWrap: {
        padding: '15px 10px',
        border: '1px solid #000',
        borderRadius: 6,
        marginRight: 15,
    },
    searchResults: {
        padding: '15px 10px',
        border: '1px solid #000',
        borderRadius: 6,
        width: 240,
        height: 300,
        overflowY: 'scroll',
    },
    searchResultItem: {
        width: '100%',
        paddingTop: 7,
        fontSize: 14,
        borderBottom: '1px solid #000',
        paddingBottom: 5,
        cursor: 'pointer'
    },
    pieChartWrap: {
        maxWidth: 500,
        width: '100%',
        marginRight: 20
    }
});
