import gql from 'graphql-tag';

import { Pagination } from '../fragments/Pagination';
import { Promotion } from '../fragments/Promotion';

export const listPromotions = gql`
  query listPromotions(
    $filter: ListPromotionsFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listPromotions(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        ...Promotion
      }
      totalCount
    }
  }
  ${Pagination}
  ${Promotion}
`;
