import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import MuiAppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { UserMenu, HideOnScroll } from 'react-admin';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HeaderIcon from './HeaderIcon';

const useStyles = makeStyles({
  toolbar: {
    padding: '10px 30px',
    justifyContent: 'space-between',
  },
  userMenu: {
    display: 'flex',
    flexDirection: 'row',
  },
  email: {
    color: '#FFFFFF',
    fontSize: 16,
  },
  icon: {
    color: '#FFFFFF',
    width: 20,
    height: 20,
  }
});

const CustomUserMenu = (props: any) => {
  const userEmail = window.localStorage.getItem('userEmail');
  const classes = useStyles();
  return (
    <UserMenu
      {...props}
      icon={
        <div className={classes.userMenu}>
          <span className={classes.email}>
            {userEmail}
          </span>
          <ExpandMoreIcon className={classes.icon}/>
        </div>
      }
      label=""
    />
  );
};

const AppBar = ({
  logout,
  ...rest
}) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <HideOnScroll>
      <MuiAppBar color="primary" {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <HeaderIcon />
          {cloneElement(<CustomUserMenu />, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  userMenu: PropTypes.element,
};

export default AppBar;