import React from 'react';
import { Create, TextInput, required } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from '../components/SimpleForm';

const useStyles = makeStyles({
  input: {
    width: '50%',
  },
});

const WordCreate = (props: any) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm title="resources.spams.create">
        <TextInput
          source="word"
          label="resources.spams.fields.word"
          validate={required()}
          className={classes.input}
        />
      </SimpleForm>
    </Create>
  );
};

export default WordCreate;
