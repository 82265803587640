import React, { useState, useEffect } from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { createMuiTheme } from '@material-ui/core/styles';

import './App.css';

import { Login, Layout, theme } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import users from './users';
import groups from './groups';
import articles from './articles';
import interests from './interests';
import categories from './categories';
import spamWords from './spamWords';
import promotions from './promotions';
import answers from './answers';
import advertising from './advertising';
import posts from './posts';

import authProviderFactory from './authProvider';
import graphqlProviderFactory from './api/graphql';
import Session from './services/session';
import ApolloClient from './api/client';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en');

const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API}/graphql`,
  name: 'admin',
  session: Session,
});

const graphqlProvider = graphqlProviderFactory({
  client,
});

const authProvider = authProviderFactory({ client });

const App = () => {
  const [dataProvider, setDataProvider] = useState(null);

  useEffect(() => {
    const fetchDataProvider = async () => {
      try {
        const dataProviderInstance = await graphqlProvider;
        setDataProvider(() => dataProviderInstance);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataProvider();
  }, []);

  if (!dataProvider) {
    return (
      <div className="loader-container">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <Admin
      title="Healthread Admin"
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      theme={createMuiTheme(theme)}
    >
      <Resource name="users" {...users} />
      <Resource name="groups" {...groups} />
      <Resource name="articles" {...articles} />
      <Resource name="posts" {...posts} />
      <Resource name="categories" {...categories} />
      <Resource name="interests" {...interests} />
      <Resource name="spams" {...spamWords} />
      <Resource name="promotions" {...promotions} />
      <Resource name="answers" {...answers} />
      <Resource name="countries" />
      <Resource name="advertising" {...advertising} />
      <Resource name="dashboard" show={Dashboard} />
      <Resource name="asa" />
      <Resource name="export" />
      <Resource name="blocked" />
    </Admin>
  );
};

export default App;
