import gql from 'graphql-tag';

export const Story = gql`
  fragment Story on Story {
    id
    label
    createdAt
    updatedAt
  }
`;
