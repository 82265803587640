import {useEffect, useState} from "react";

export function formatDate(date:Date) {
    if(!date) return '';

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const COLORS_EMOTION = [{name:'feeling_strong', color:'#598eff'}, {name:'feeling_better', color:'#71b8cb'}, {name:'making_it', color:'#7fa648'},
    {name:'less_stressed', color:'#f4ca34'}, {name:'anxious', color:'#f3a761'}, {name:'in_need_of_support', color:'#e9655b'}];

export const COLORS_STORY_TYPE = [{name:'Better now', color:'#598eff'}, {name:'Encouraging', color:'#71b8cb'}, {name:'How I did it', color:'#7fa648'}, {name:'How i did it', color:'#7fa648'},
    {name:'My journey', color:'#f4ca34'}, {name:'Overwhelmed', color:'#f3a761'}, {name:'Working through', color:'#e9655b'}];

export const RADIAN = Math.PI / 180;

export function useDebounce(value: string | null, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}
