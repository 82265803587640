import React from 'react';
import { Create } from 'react-admin';

import PostForm from '../components/PostForm';

const AdvertisingCreate = (props: any) => {
  return (
    <Create {...props}>
      <PostForm title="resources.posts.create" />
    </Create>
  );
};

export default AdvertisingCreate;
