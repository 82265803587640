import React from 'react';
import {
  TextField,
  Datagrid,
  useMutation,
  useRefresh,
  useNotify,
} from 'react-admin';

import ListWithTitle from '../components/ListWithTitle';
import ListActions from '../components/ListActions';
import ArrayField from '../components/ArrayField';
import ActionsCell from '../components/ActionsCell';
import DataField from '../components/DataField';
import EmailField from '../components/EmailField';
import { Attachment } from '../api/fragments/types/Attachment';
import { exportUsersVariables } from '../api/mutations/types/exportUsers';

import VisitorFilter from './VisitorFilter';
import downloadCSV from './downloadCSV';

const VisitorList = (props: any) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [block] = useMutation(
    {},
    {
      onSuccess: () => {
        refresh();
        notify('The user has been blocked', 'info');
      },
    },
  );
  const handleBlock = (id: string) =>
    block({
      type: 'delete',
      resource: 'blocked',
      payload: {
        id,
      },
    });

  const [mutate] = useMutation(
    {},
    {
      onSuccess: ({ data }: { data: { file: Attachment } }) =>
        downloadCSV(data.file.url),
    },
  );
  const exporter = (payload: exportUsersVariables) => {
    mutate({
      type: 'getOne',
      resource: 'export',
      payload,
    });
  };

  return (
    <ListWithTitle
      {...props}
      filters={<VisitorFilter />}
      exporter={exporter}
      perPage={25}
      bulkActionButtons={false}
      actions={<ListActions hasCreate={false} />}
      sort={{ field: 'createdAt', order: 'ASC' }}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.users.fields.user_id"
          sortable={false}
        />
        <TextField
          source="fullName"
          label="resources.users.fields.name"
          sortable={false}
        />
        <EmailField
          source="email"
          label="resources.users.fields.email"
          sortable={false}
        />
        <TextField
          source="phoneNumber"
          label="resources.users.fields.mobile"
          sortable={false}
        />
        <TextField
          source="country.name"
          label="resources.users.fields.country"
          sortable={false}
        />
        <TextField
          source="gender"
          label="resources.users.fields.gender"
          sortable={false}
        />
        <TextField
          source="role"
          label="resources.users.fields.user_type"
          sortable={false}
        />
        <TextField
          source="mainInterests.nodes.0.label"
          label="resources.users.fields.main_interest"
          sortable={false}
        />
        <ArrayField
          source="interests"
          label="resources.users.fields.other_interests"
        />
        <DataField
          source="createdAt"
          label="resources.groups.fields.date"
          sortable={true}
        />
        <ActionsCell isEdit={false} onBlock={handleBlock} />
      </Datagrid>
    </ListWithTitle>
  );
};

export default VisitorList;
