import React, { FC, Fragment } from 'react';
import {
  ReferenceArrayInput,
  TextInput,
  required,
  AutocompleteArrayInput,
  ImageInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';

import SimpleForm from './SimpleForm';
import VideoField from './VideoField';
import AvatarUpload from './AvatarUpload';

const iconSize = 40;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '50%',
    height: '40%',
    minHeight: 300,
    minWidth: 500,
    backgroundColor: 'rgba(193, 206, 222, .3)',
    borderRadius: 4,
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      top: 48,
      position: 'relative',
      left: 13,
    },
  },
  removeButton: {
    display: 'inline-block',
    '& button': {
      position: 'absolute',
      top: 17,
      left: '40%',
      maxWidth: iconSize,
      maxHeight: iconSize,
      minWidth: iconSize,
      minHeight: iconSize,
      zIndex: 2,
      backgroundColor: 'rgba(255,255,255,.7)',
      padding: 0,
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: 0,
    borderRadius: 4,
  },
  dropZone: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,.7)',
    padding: '10px 20px',
    borderRadius: 20,
    margin: 10,
    '& p': {
      margin: 0,
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
    },
  },
  input: {
    width: '50%',
    '& .MuiFormControl-root': {
      width: '50%',
    },
  },
});

interface Props {
  title: string;
  resource?: string;
  isRequiredOwner?: boolean;
}

const PostForm: FC<Props> = ({ resource, isRequiredOwner = true, ...rest }) => {
  const classes = useStyles();
  const isArticlesPosts = resource === 'articles';
  const isNotAdvertising = resource !== 'advertising';
  const ContentInput = isNotAdvertising ? RichTextInput : TextInput;

  return (
    <SimpleForm {...rest}>
      <AvatarUpload
        source="ownerAvatar"
        title={`resources.${resource}.fields.upload_photo`}
        isRequired={isRequiredOwner}
      />
      <TextInput
        source="ownerName"
        label={`resources.${resource}.fields.owner_name`}
        validate={isRequiredOwner ? required() : null}
        className={classes.input}
      />
      {resource !== 'posts' && (
        <TextInput
          source="title"
          label={`resources.${resource}.fields.title`}
          validate={required()}
          className={classes.input}
        />
      )}
      <ReferenceArrayInput
        source="interestIds"
        label="resources.articles.fields.health_interests"
        reference="interests"
        validate={required()}
        filterToQuery={(label: string) => ({ label })}
        className={classes.input}
      >
        <AutocompleteArrayInput
          optionValue="id"
          optionText="label"
          translateChoice={false}
        />
      </ReferenceArrayInput>

      {isNotAdvertising && resource !== 'posts' && (
        <TextInput
          source="shortDescription"
          label={`resources.${resource}.fields.short_description`}
          validate={required()}
          multiline
          className={classes.input}
          rows="6"
          fullWidth={false}
        />
      )}
      {isNotAdvertising && (
        <>
          <div>Intro photo/video</div>
          <ImageInput
            source="introImages.0"
            label=""
            accept="image/*, video/mp4"
            labelSingle="Select photo/video"
            classes={classes}
          >
            <VideoField source="thumbUrl" title="title" classes={classes} />
          </ImageInput>
        </>
      )}
      <ContentInput
        source="content"
        label={`resources.${resource}.fields.text`}
        validate={required()}
        multiline
        className={classes.input}
        rows="10"
        fullWidth={false}
      />
      <div>Preview photo/video</div>
      <ImageInput
        source="attachments.0"
        label=""
        accept="image/*, video/mp4"
        labelSingle="Select photo/video"
        classes={classes}
      >
        <VideoField source="thumbUrl" title="title" classes={classes} />
      </ImageInput>
      {
        isArticlesPosts &&
          <TextInput
              source="createdAt"
              label={`Date created at`}
              validate={required()}
              className={classes.input}
              initialValue={new Date()}
          />
      }

      {isNotAdvertising && (
        <Fragment>
          <BooleanInput
            source="pickedByEditor"
            label="resources.articles.fields.picked"
          />
          <ReferenceInput
            source="categoryId"
            label="resources.articles.fields.category"
            reference="categories"
            filterToQuery={(title: string) => ({ title })}
            className={classes.input}
          >
            <AutocompleteInput
              optionValue="id"
              optionText="title"
              translateChoice={false}
            />
          </ReferenceInput>
        </Fragment>
      )}
    </SimpleForm>
  );
};

export default PostForm;
