import AdvertisingIcon from "@material-ui/icons/BusinessCenter";

import AdvertisingList from "./AdvertisingList";
import AdvertisingCreate from "./AdvertisingCreate";
import AdvertisingEdit from "./AdvertisingEdit";

export default {
  list: AdvertisingList,
  icon: AdvertisingIcon,
  create: AdvertisingCreate,
  edit: AdvertisingEdit,
};
