import React from 'react';
import { TextField, Datagrid, useTranslate } from 'react-admin';

import ListWithTitle from '../components/ListWithTitle';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';

const WordsList = (props: any) => {
  const translate = useTranslate();
  return (
    <ListWithTitle
      {...props}
      pagination={false}
      bulkActionButtons={false}
      exporter={false}
      title={translate('resources.spams.name', 2)}
      actions={<ListActions label="resources.spams.create_button" />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.spams.fields.word"
          sortable={false}
        />
        <ActionsCell isEdit={false} />
      </Datagrid>
    </ListWithTitle>
  );
};

export default WordsList;
