
import gql from 'graphql-tag';


export const getStoryTypesStatistic = gql`
    query getStoryTypesStatistic($filter: GetStoryTypesStatisticFilterInput) {
        data: getStoryTypesStatistic(
            filter: $filter
        )
    }
`;
