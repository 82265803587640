import gql from 'graphql-tag';

import { Pagination } from '../fragments/Pagination';

export const listAnswers = gql`
  query listAnswers(
    $filter: ListAnswersFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listAnswers(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        id
        text
      }
      totalCount
    }
  }
  ${Pagination}
`;
