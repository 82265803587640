import gql from "graphql-tag";

import { Pagination } from "../fragments/Pagination";
import { Group } from "../fragments/Group";

export const listGroups = gql`
  query listChatGroups(
    $filter: ListChatGroupsFilterInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    data: listChatGroups(
      filter: $filter
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      pagination: pageInfo {
        ...Pagination
      }
      nodes {
        ...Group
      }
      totalCount
    }
  }
  ${Group}
  ${Pagination}
`;
