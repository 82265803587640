import React, { FC } from "react";
import { Record } from "ra-core";
import { ImageField } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import { FieldProps } from "../types";

const useStyles = makeStyles({
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 'none',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: 0,
    borderRadius: 4,
  },
});

const VideoField: FC<FieldProps<Record>> = (props) => {
  const { record: { thumbUrl, rawFile } } = props;
  const classes = useStyles();
  return rawFile?.type.includes('video') ? (
    <video className={classes.image}>
      <source src={thumbUrl} />
      Your browser does not support the video tag.
    </video>
  ) : (
    <ImageField
      {...props}
    />
  );
}

export default VideoField;
