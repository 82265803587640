import React from 'react';
import { TextField, Datagrid, Filter } from 'react-admin';

import SearchInput from '../components/SearchInput';
import ArrayField from '../components/ArrayField';
import ListWithTitle from '../components/ListWithTitle';
import InterestInput from '../components/InterestInput';
import DataField from '../components/DataField';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';

const GroupsFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput
      source="text"
      placeholder={'resources.groups.search'}
      alwaysOn
    />
    <InterestInput
      source="interestIds"
      label="resources.groups.fields.health_interests"
      isArray
      alwaysOn
    />
  </Filter>
);

const GroupsList = (props: any) => {
  return (
    <ListWithTitle
      {...props}
      filters={<GroupsFilter />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      actions={<ListActions label="resources.groups.create_button" />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.groups.fields.group_id"
          sortable={false}
        />
        <TextField
          source="title"
          label="resources.groups.fields.group_name"
          sortable={false}
        />
        <ArrayField
          source="interests"
          label="resources.groups.fields.health_interests"
        />
        <TextField
          source="usersCount"
          label="resources.groups.fields.members"
          sortable={false}
        />
        <DataField
          source="createdAt"
          label="resources.groups.fields.date"
          sortable={false}
        />
        <ActionsCell />
      </Datagrid>
    </ListWithTitle>
  );
};

export default GroupsList;
