import gql from "graphql-tag";

export const typename = "User";

export const Attachment = gql`
  fragment Attachment on ASA {
    id
    url
    thumbUrl
    width
    height
    contentType
  }
`;
