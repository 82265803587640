import React, { FC } from 'react';
import { TextField, Datagrid, useTranslate, Filter } from 'react-admin';
import { Record } from 'ra-core';

import ListWithTitle from '../components/ListWithTitle';
import DataField from '../components/DataField';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';
import SearchInput from '../components/SearchInput';
import { FieldProps } from '../types';

const OptionsField: FC<FieldProps<Record>> = ({ record }) => (
  <span>{Object.values(record?.options).join(', ')}</span>
);

const PromotionsFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput
      source="title"
      placeholder="resources.promotions.search"
      alwaysOn
    />
  </Filter>
);

const PromotionsList = (props: any) => {
  const translate = useTranslate();
  return (
    <ListWithTitle
      {...props}
      filters={<PromotionsFilter />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      title={translate('resources.promotions.name', 2)}
      actions={<ListActions label="resources.promotions.create_button" />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label="resources.promotions.fields.id"
          sortable={false}
        />
        <TextField
          source="title"
          label="resources.promotions.fields.title"
          sortable={false}
        />
        <TextField
          source="kind"
          label="resources.promotions.fields.kind"
          sortable={false}
        />
        <OptionsField
          label="resources.promotions.fields.answers"
          sortable={false}
        />
        <DataField
          source="date"
          label="resources.promotions.fields.date"
          sortable={false}
        />
        <ActionsCell />
      </Datagrid>
    </ListWithTitle>
  );
};

export default PromotionsList;
