import React from 'react';
import {
  Filter,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  NumberInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import SearchInput from '../components/SearchInput';
import InterestInput from '../components/InterestInput';

const useStyles = makeStyles({
  input: {
    marginBottom: 10,
    '& p': {
      display: 'none',
    },
  },
});

const VisitorFilter = (props: any) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput
        source="text"
        placeholder="resources.users.search"
        alwaysOn
      />
      <SearchInput
        source="email"
        placeholder="resources.users.fields.email"
        alwaysOn
        fullWidth
      />
      <SearchInput
        source="phoneNumber"
        placeholder="resources.users.fields.mobile"
        alwaysOn
        fullWidth
      />
      <NumberInput
        source="ageFrom"
        inputProps={{
          min: 0
        }}
        step={1}
        label="resources.users.fields.age_from"
        alwaysOn
        className={classes.input}
        fullWidth
      />
      <NumberInput
        source="ageTo"
        inputProps={{
          min: 0
        }}
        step={1}
        label="resources.users.fields.age_to"
        alwaysOn
        className={classes.input}
        fullWidth
      />
      <SelectInput
        source="gender"
        label="resources.users.fields.gender"
        choices={[
          { id: 'male', text: 'Male' },
          { id: 'female', text: 'Female' },
          { id: 'other', text: 'Other' },
        ]}
        optionValue="id"
        optionText="text"
        translateChoice={false}
        alwaysOn
        className={classes.input}
        fullWidth
        emptyValue=""
        emptyText="Any"
        allowEmpty
      />
      <ReferenceInput
        source="countryId"
        label="resources.users.fields.country"
        reference="countries"
        filterToQuery={(name: string) => ({ name })}
        alwaysOn
        className={classes.input}
        fullWidth
        emptyValue=""
        emptyText="Any"
        allowEmpty
      >
        <AutocompleteInput
          optionValue="id"
          optionText="name"
          translateChoice={false}
        />
      </ReferenceInput>
      <SelectInput
        source="role"
        label="resources.users.fields.user_type"
        choices={[
          { id: 'regular', text: 'Regular' },
          { id: 'pro', text: 'Pro' },
        ]}
        optionValue="id"
        optionText="text"
        translateChoice={false}
        alwaysOn
        className={classes.input}
        fullWidth
        emptyValue=""
        emptyText="Any"
        allowEmpty
      />
      <InterestInput
        source="interestIds"
        label="resources.users.fields.health_interests"
        isArray
        alwaysOn
      />
    </Filter>
  );
};

export default VisitorFilter;
