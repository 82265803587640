import { AuthProvider } from "ra-core";

import Session from "./services/session";
import { signIn } from "./api/mutations/signIn";

const authProvider = ({ client }: any): AuthProvider => {
  
  return {
    login: async ({ email, password }) => {
      const {
        data: {
          signIn: {
            accessToken,
            refreshToken,
          }
        }
      } = await client.mutate({
        mutation: signIn,
        variables: {
          email,
          password
        }
      });

      Session.update({
        accessToken,
        refreshToken,
        userEmail: email,
      });
      
      return accessToken ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
      Session.delete();
      client.resetStore();
      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      const token = await Session.getAccessToken();
      return token ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => Promise.reject("Unknown method"),
  };
};

export default authProvider;
