import React, { useMemo } from 'react';
import {
  TextField,
  Datagrid,
  ReferenceInput,
  Filter,
  AutocompleteInput,
  useTranslate,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin';

import SearchInput from '../components/SearchInput';
import ListWithTitle from '../components/ListWithTitle';
import InterestInput from '../components/InterestInput';
import DataField from '../components/DataField';
import ListActions from '../components/ListActions';
import ActionsCell from '../components/ActionsCell';
import ArrayField from '../components/ArrayField';

import { Post } from '../api/fragments/types/Post';

import ShareSVG from '../assets/icons/share';
import CommentsSVG from '../assets/icons/comments';

import LongTextField from './LongTextField';
import LikeIcon from "../assets/icons/new-like";
import HelpfulIcon from "../assets/icons/HelpfulIcon";
import SupportIcon from "../assets/icons/support";
import ConnectIcon from "../assets/icons/connect";

const PostsFilter = (props: any) => {
  const isUsersPosts = props.resource === 'posts';
  console.log('props',props)
  return (
    <Filter {...props}>
      <SearchInput
        source="q"
        placeholder={`resources.${props.resource}.search`}
        alwaysOn
        fullWidth
      />
      {!isUsersPosts && (
        <InterestInput
          source="interestId"
          label="resources.articles.fields.health_interests"
          isArray={false}
          alwaysOn
        />
      )}
      {!isUsersPosts && (
        <ReferenceInput
          source="authorId"
          label="resources.articles.fields.author"
          reference="users"
          filterToQuery={(text: string) => ({ text })}
          alwaysOn
          fullWidth
          emptyValue=""
          emptyText="Any"
          allowEmpty
          sort={false}
        >
          <AutocompleteInput
            optionValue="id"
            optionText="fullName"
            translateChoice={false}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const PostsList = (props: any) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const title = useMemo(() => {
    switch (props?.filter?.kind) {
      case 'advertisement':
        return 'advertising';
      case 'info':
        return 'articles';
      case 'user':
        return 'posts';
      default:
        return '';
    }
  }, [props]);

  const [pin] = useMutation(
    {},
    {
      onSuccess: (data) => {
        const { pinned } = data.data.data.data;

        refresh();
        notify(
          translate(`resources.${title}.pin_success`, {
            pinned: pinned ? 'pinned' : 'unpinned',
          }),
          'info',
        );
      },
    },
  );

  const handlePin = (payload: Post) =>
    pin({
      type: 'update',
      resource: props.resource,
      payload: { data: payload },
    });
  console.log(props)
  const isUsersPosts = props.resource === 'posts';
  const isAdvertisement = props.resource === 'advertising';

  const newObject = { ...props };
  delete newObject.filter;

  const LikeField = props => {
    const { record = {} } = props;
    return <span>{record.emotionStatistic?.like || "0"}</span>;
  };

  const ConnectField = props => {
    const { record = {} } = props;
    return <span>{record.emotionStatistic?.connect || "0"}</span>;
  };

  const InformField = props => {
    const { record = {} } = props;
    return <span>{record.emotionStatistic?.inform || "0"}</span>;
  };

  const SupportField = props => {
    const { record = {} } = props;
    return <span>{record.emotionStatistic?.support || "0"}</span>;
  };


  return (
    <ListWithTitle
      {...newObject}
      filters={<PostsFilter />}
      sort={isUsersPosts && { field: 'createdAt', order: 'ASC' }}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      title={title != 'posts' ? translate(`resources.${title}.name`, 2) : 'Feed'}
      actions={<ListActions label={`resources.${title}.create_button`} />}
    >
      <Datagrid optimized>
        <TextField
          source="id"
          label={`resources.${title}.fields.id`}
          sortable={false}
        />

        {isAdvertisement && (
          <TextField
            source="ownerName"
            label={`resources.${title}.fields.owner_name`}
            sortable={false}
          />
        )}

        {!isUsersPosts && (
          <TextField
            source="title"
            label={`resources.${title}.fields.title`}
            sortable={false}
          />
        )}

        {!isUsersPosts && (
          <TextField
            source="attachments.length"
            label={`resources.${title}.fields.attachment`}
            sortable={false}
          />
        )}

        {isUsersPosts && (
          <LongTextField
            source="content"
            label="resources.posts.fields.text"
            sortable={false}
          />
        )}

        {isUsersPosts && (
            <LikeField
                source="emotionStatistic.like"
                label={<LikeIcon />}
                sortable={false}
            />
        )}
        {isUsersPosts && (
            <InformField
                source="emotionStatistic.inform"
                label={<HelpfulIcon />}
                sortable={false}
            />
        )}
        {isUsersPosts && (
            <ConnectField
                source="emotionStatistic.connect"
                label={<ConnectIcon />}
                sortable={false}
            />
        )}
        {isUsersPosts && (
            <SupportField
                source="emotionStatistic.support"
                label={<SupportIcon />}
                sortable={false}
            />
        )}
        {isUsersPosts && (
          <TextField
            source="commentsCount"
            label={<CommentsSVG />}
            sortable={false}
          />
        )}
        {isUsersPosts && (
          <TextField
            source="userSharesCount"
            label={<ShareSVG />}
            sortable={false}
          />
        )}

        <TextField
          source="engagementTotalCount"
          label={`resources.${title}.fields.engagement_total_count`}
          sortable={false}
        />

        {isUsersPosts && (
          <ArrayField
            source="storyTypes"
            label="resources.posts.fields.story_types"
            sortable={false}
          />
        )}

        {!isUsersPosts && (
          <ArrayField
            source="interests"
            label={`resources.${title}.fields.health_interests`}
          />
        )}

        <DataField
          source="createdAt"
          label={`resources.${title}.fields.date`}
          sortable={false}
        />
        <ActionsCell onPin={handlePin} />
      </Datagrid>
    </ListWithTitle>
  );
};

export default PostsList;
