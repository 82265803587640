import React from "react";
import {
  Edit,
} from "react-admin";

import GroupForm from "./GroupForm";

const GroupEdit = (props: any) => {
  return (
    <Edit {...props}>
      <GroupForm title="resources.groups.edit" />
    </Edit>
  );
};

export default GroupEdit;
