import gql from "graphql-tag";

import { Group } from "../fragments/Group";

export const getGroup = gql`
  query getChatGroup($id: ID!) {
    data: getChatGroup(id: $id) {
      ...Group
    }
  }
  ${Group}
`;
