import React from 'react';

const Comments = () => {
  return (
    <svg
      width="22px"
      height="22px"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50432277 21.05c-.20604737 0-.40553061-.0383622-.56759138-.1194659-.47749104-.2089023-.77923859-.6866693-.77923859-1.2273641l.04008202-2.9192932C2.74912934 16.2883581.95 14.074045.95 11.5475504V6.30259366C.95 3.35439123 3.34538602.95 6.30259366.95h9.71181554c2.9482025 0 5.3525937 2.39538602 5.3525937 5.35259366v5.27377524c0 2.9482024-2.395386 5.3525936-5.3525937 5.3525936h-4.0057637c-.1332737 0-.2956577.054128-.3995889.1319276l-4.23364362 3.6574624c-.22734339.2273433-.51790496.3316475-.87109021.3316475zM6.30259366 2.49092219c-2.1049506 0-3.81167147 1.70672087-3.81167147 3.81167147v5.27377524c0 1.9066837 1.42316012 3.5270287 3.29878154 3.754377.51261216.0603073.90871126.5116761.90871126 1.0294846l-.08280894 2.9593879 3.97745707-3.4586523c.39476541-.3340322.89788328-.5305626 1.38676398-.5305626h4.0057637c2.1049506 0 3.8116714-1.7067209 3.8116714-3.8116715V6.30259366c0-2.1049506-1.7067208-3.81167147-3.8116714-3.81167147H6.30259366z"
        fill="#2C2F49"
        stroke="#2C2F49"
        stroke-width=".1"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default Comments;
