import React, { cloneElement } from 'react';
import {
  CreateButton,
  TopToolbar,
  sanitizeListRestProps,
  useTranslate,
} from 'react-admin';
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  toolBar: {
    paddingTop: 15,
  },
  button: {
    borderRadius: 20,
    color: '#FFFFFF',
    backgroundColor: theme.palette.secondary.main,
    padding: '5px 20px',
    boxShadow: 'none',
    marginLeft: 20,
    position: 'absolute',
    right: 0,
    top: -60,
    width: 200,
    '&:hover': {
      backgroundColor: 'rgb(79, 128, 142)',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      color: '#FFFFFF',
    },
    '& span': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      fontSize: 16,
    },
  },
}));

const ListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
  hasCreate = true,
  label,
  exporter,
  permanentFilter,
  total,
  ...rest
}: any) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <TopToolbar className={classes.toolBar} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? (
        <CreateButton
          basePath={basePath}
          className={classes.button}
          label={translate(label)}
        />
      ) : null}
      {exporter ? (
        <Button
          variant="contained"
          onClick={() => exporter({ filter: { ...filterValues, ...permanentFilter } })}
          color="primary"
          disabled={total === 0}
          fullWidth
          className={classes.button}
        >
          {translate('pos.export')}
        </Button>
      ) : null}
    </TopToolbar>
  );
};

export default ListActions;
